import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import StoryComponent from "../storyComponent/StoryComponent";
import "react-multi-carousel/lib/styles.css";
import "./SwitchProjects.css";
import "video-react/dist/video-react.css";
import { Player } from "video-react";
import { useTranslation } from "react-i18next";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
import { useHistory } from "react-router-dom";

import { getAllProjects, getProjectsReport } from "../../../api/account";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import Loading from "../../ui/loading";
import { toastifyMessage } from "../../../handlers/toastifyMessage";
import { getProjectPDF } from "../../../api";

import pdf_icons from "../../../assets/pdf_1.svg";
import pdf_download from "../../../assets/ped_download.svg";

import { Autoplay, Pagination } from "swiper";

import pdf_image from "../../../assets/pexels-hemin-suthar-4727408.jpg";

//viewer pdf

const SwitchProjects = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const history = useHistory();

  SwiperCore.use([Navigation]);
  const [view, setView] = useState(5);
  const [view2, setView2] = useState(5);
  const [displayedItem, setDisplayedItem] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingReportDetails, setLoadingReportDetails] = useState(true);
  const [selectedItem, setSelectedItem] = useState(1);
  const [selectedProject, setSelectedProject] = useState(null);
  const sponID = useSelector((state) => {
    return state.auth.extraSponID;
  });

  function sponserId() {
    if (sponID) return sponID;
    return getUserInfo();
  }
  useEffect(() => {
    if (!userLogin) {
      history.push("/");
    }
  }, []);

  const breakpoints = {
    // Breakpoint for small screens
    768: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    // Breakpoint for medium screens
    1024: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    // Breakpoint for large screens
    1440: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
  };

  // useEffect(() => {
  //   if (displayedItem != "") {
  //     var elem = document.getElementsByClassName("month_year_item");
  //     for (let i = 0; i < elem.length; i++) {
  //       elem[i].style.backgroundColor = "transparent";
  //       elem[i].style.color = "black";
  //     }
  //     document.getElementById(displayedItem).style.backgroundColor = "#ee4236";
  //     document.getElementById(displayedItem).style.color = "#fff";
  //   }
  // }, [displayedItem]);

  const handleDonationNavi = (str) => {
    setDisplayedItem(str);
  };
  useEffect(() => {
    let mounted = true;
    var firstProject;
    async function fetchAllProjects() {
      const allProjectsFromApi = await getAllProjects(
        sponserId()?.sponser_id,
        lng === "arab" ? "1" : "2"
      );
      if (allProjectsFromApi?.data?.length > 0) {
        if (mounted) {
          setAllProjects(allProjectsFromApi?.data);
          firstProject = allProjectsFromApi?.data[0];
          setSelectedItem(firstProject?.projectID);
          setSelectedProject(firstProject);
          getProjectDetails(firstProject);
        }
      } else {
        if (mounted) {
          setLoadingReportDetails(false);
        }
      }
      if (mounted) {
        setLoading(false);
      }
    }
    fetchAllProjects();
    //Call APIs here
    return () => {
      mounted = false;
    };
  }, []);

  const userInfo = useSelector((state) => {
    return state.auth.authData;
  });
  function getUserInfo() {
    if (
      userInfo?.Results &&
      userInfo?.Results.length > 0 &&
      userInfo?.Results[0]
    ) {
      return userInfo?.Results[0];
    }
  }

  const [allProjects, setAllProjects] = useState([]);
  const [projectReportDetails, setProjectReportDetails] = useState([]);
  const options = {
    year: "numeric",
    month: "short",
  };

  let genrate_pdf_value = projectReportDetails;

  const getProjectDetails = async (project) => {
    setLoadingReportDetails(true);
    setProjectReportDetails([]);
    const projectDetailsFromApi = await getProjectsReport(
      sponserId()?.sponser_id,
      project.projectID,
      project.projectYear
    );
    var id = 0;
    var customReportData = projectDetailsFromApi.data.data;
    if (customReportData.length > 0) {
      customReportData.forEach((report) => {
        id++;
        report.customId = id;
      });
    }
    setProjectReportDetails(customReportData);
    setDisplayedItem(customReportData.slice(0).shift()?.customId);
    setLoadingReportDetails(false);
  };
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    if (window.innerWidth <= 900) {
      setView2(3);
    } else {
      setView2(4.8);
    }
  }, [width]);
  useEffect(() => {
    if (window.innerWidth > 3400 && window.innerWidth <= 4000) {
      setView(15);
    } else if (window.innerWidth > 2530 && window.innerWidth <= 3400) {
      setView(11);
    } else if (window.innerWidth > 1860 && window.innerWidth <= 2530) {
      setView(8);
    } else if (window.innerWidth > 1400 && window.innerWidth <= 1860) {
      setView(6);
    } else if (window.innerWidth > 1111 && window.innerWidth <= 1400) {
      setView(4.5);
    } else if (window.innerWidth > 700 && window.innerWidth <= 1111) {
      setView(4.5);
    } else if (window.innerWidth > 500 && window.innerWidth <= 700) {
      setView(3.2);
    } else if (window.innerWidth > 300 && window.innerWidth <= 500) {
      setView(2.5);
    } else {
      setView(1.8);
    }
  }, [width]);

  const handleProjectSelect = (id) => {
    if (loadingReportDetails) {
      return;
    }
    setSelectedItem(id);
    var projectSelected = allProjects.find(
      (project) => project.projectID == id
    );
    getProjectDetails(projectSelected);
    setSelectedProject(projectSelected);
    setDisplayedItem("");
  };

  const ProjectSection = () => {
    if (loading) {
      return (
        <div
          style={{
            minHeight: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="grow" />
        </div>
      );
    }
    if (allProjects.length > 0) {
      return (
        <Swiper
          loop={allProjects?.length > view ? true : false}
          slidesPerView={view}
          navigation={true}
        >
          {allProjects.map((project) => (
            <SwiperSlide key={project.projectID}>
              <StoryComponent
                key={project.projectID}
                itemId={project.projectID}
                img={project.project_Type_Path}
                title={project.projectTypeName}
                selectedItem={selectedItem}
                setSelectedItem={handleProjectSelect}
                setOrphanAmount={() => {}}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      );
    }
    return (
      <div
        style={{
          minHeight: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span>{t("No Projects found")}</span>
      </div>
    );
  };

  const ReportDetailsRow = (props) => {
    return (
      <div className="reportDetailKey">
        <span className="reportCustomKey">{t(`${props.customKey}`)}:</span>
        <span
          style={{
            textAlign:
              lng === "arab"
                ? width <= 460
                  ? "left"
                  : "right"
                : width <= 460
                ? "right"
                : "left",
          }}
          className={
            props.customKey === "Project No"
              ? "payment_numbers reportCustomValue"
              : props.customKey === "Total Cost"
              ? "payment_numbers reportCustomValue"
              : props.customKey === "Project Year"
              ? "payment_numbers reportCustomValue"
              : props.customKey === "Work in Percentage"
              ? "payment_numbers reportCustomValue"
              : "reportCustomValue"
          }
        >
          {props.customValue}
        </span>
      </div>
    );
  };

  const sendReportByEmail = () => {
    var selectedReport = projectReportDetails.find(
      (report) => report.customId == displayedItem
    );

    if (selectedReport) {
      var sponser_id = getUserInfo()?.sponser_id;
      var project_id = selectedReport?.projectID;
      var project_year = selectedReport?.projectYear;
      var report_id = selectedReport?.projectReportID;
      getProjectPDF(sponser_id, project_id, project_year, report_id).then(
        (data) => {
          var binary = "";
          var bytes = new Uint8Array(data.data);
          var len = bytes.byteLength;
          for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          var link = document.createElement("a");
          link.href =
            "data:application/octet-stream;base64," + window.btoa(binary);

          //Set properties as you wise
          link.download = `Project ${project_id} Year ${project_year} for sponsor ${sponser_id}  Report ${report_id}.pdf`;
          link.target = "blank";

          //this part will append the anchor tag and remove it after automatic click
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      );
    } else {
      //toastifyMessage("There is no report for thit project", undefined, 'error')
    }
  };

  //get pdf

  var selectedReport = projectReportDetails.find(
    (report) => report.customId == displayedItem
  );

  const [pdfArrayBuffer, setPdfArrayBuffer] = useState(null);

  const GenaratePdf = async () => {
    var sponser_id = getUserInfo()?.sponser_id;
    var project_id = selectedReport?.projectID;
    var project_year = selectedReport?.projectYear;
    var report_id = selectedReport?.projectReportID;

    try {
      await getProjectPDF(sponser_id, project_id, project_year, report_id).then(
        (res) => {
          setPdfArrayBuffer(res.data);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GenaratePdf();
  }, []);

  return (
    <div
      className="switch_project_page_wraper"
      style={{ minHeight: "75vh", width: "100%" }}
    >
      <ShareHeader pageName={t("My Projects")} noShare={true} />
      <div className="switch_projects ">
        <div
          style={
            lng === "arab"
              ? { direction: "rtl", width: "100%" }
              : { width: "100%" }
          }
        >
          <React.Fragment>
            {loading ? <Loading /> : <ProjectSection />}
          </React.Fragment>
        </div>
        <div className="main_project">
          <div className="pdf_viewer">
            <div className="child_viewer">
              <iframe
                src={URL.createObjectURL(
                  new Blob([pdfArrayBuffer], { type: "application/pdf" })
                )}
                className="pdf_iframe"
                title="PDF Viewer"
              />
              <div className="child_pdf_main">
                <div className="pdf_btns">
                  <img src={pdf_icons} alt="pdf_icons" />
                  <p>PDF Preview</p>
                </div>
                <div className="pdf_btn">
                  {displayedItem != "" && projectReportDetails.length > 0 ? (
                    <img
                      src={pdf_download}
                      alt="pdf_downlaod"
                      onClick={sendReportByEmail}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="left_projects">
            <div className="date_pdf">
              {/* <p>Feb 23</p> */}
              <div class="dropdown">
                <p
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Feb 2023
                </p>
                <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <button class="dropdown-item" type="button">
                    Mar 2023
                  </button>
                  <button class="dropdown-item" type="button">
                    Apr 2023
                  </button>
                  <button class="dropdown-item" type="button">
                    May 2023
                  </button>
                </div>
              </div>
            </div>
            <div className="reports_dates_wraper">
              <Swiper
                spaceBetween={20}
                centeredSlides={false}
                slidesPerView={3}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                // pagination={{
                //   clickable: true,
                // }}
                // navigation={true}
                breakpoints={breakpoints}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img
                    src={pdf_image}
                    alt="pdf_image"
                    className="slider_image"
                    style={{ objectFit: "cover", borderRadius: "10px" }}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={pdf_image}
                    alt="pdf_image"
                    className="slider_image"
                    style={{ objectFit: "cover", borderRadius: "10px" }}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={pdf_image}
                    alt="pdf_image"
                    className="slider_image"
                    style={{ objectFit: "cover", borderRadius: "10px" }}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={pdf_image}
                    alt="pdf_image"
                    className="slider_image"
                    style={{ objectFit: "cover", borderRadius: "10px" }}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={pdf_image}
                    alt="pdf_image"
                    className="slider_image"
                    style={{ objectFit: "cover", borderRadius: "10px" }}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={pdf_image}
                    alt="pdf_image"
                    className="slider_image"
                    style={{ objectFit: "cover", borderRadius: "10px" }}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
            {/* <div
              className="reports_dates_wraper"
              style={lng === "arab" ? { flexDirection: "row-reverse" } : null}
            >
              <label style={{ fontWeight: "bold", display: "flex", flex: "1" }}>
                {t("Reports")}
              </label>
              <div
                className="switch_donation_header_real"
                style={{
                  width: "100%",
                  padding: "10px 20px 10px 20px",
                  boxShadow: "none",
                  height: "auto",
                  padding: "0px",
                  display: "flex",
                  flex: projectReportDetails.length == 0 ? "1" : "",
                }}
              >
                <React.Fragment>
                  {loadingReportDetails ? (
                    <Loading height="60px" />
                  ) : projectReportDetails.length > 0 ? (
                    <Swiper
                      loop={allProjects?.length > view2 ? true : false}
                      navigation={{
                        prevEl: ".prevCustom",
                        nextEl: ".nextCustom",
                      }}
                      className="swiper_forDonations2"
                      slidesPerView={view2}
                      style={{
                        width: width > 1150 ? "80%" : "100%",
                        display: "flex",
                        justifyContent: "center",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginRight: "15px",
                      }}
                    >
                      <div className="prevCustom">
                        <i className="fas fa-chevron-left"></i>
                      </div>
                      {projectReportDetails.map((reportDetails, i) => (
                        <SwiperSlide key={i}>
                          <button
                            id={reportDetails.customId}
                            onClick={(e) => {
                              e.preventDefault();
                              handleDonationNavi(reportDetails.customId);
                            }}
                            className="month_year_item payment_numbers"
                            style={{
                              backgroundColor:
                                displayedItem === reportDetails.customId
                                  ? "#ee4236"
                                  : "transparent",
                              color:
                                displayedItem === reportDetails.customId
                                  ? "white"
                                  : "black",
                            }}
                          >
                            {new Date(
                              reportDetails.projectReportDate
                            ).toLocaleDateString("en-US", options)}
                          </button>
                        </SwiperSlide>
                      ))}
                      <div className="nextCustom">
                        <i className="fas fa-chevron-right"></i>
                      </div>
                    </Swiper>
                  ) : (
                    <div>{t("No reports found")}</div>
                  )}
                </React.Fragment>
              </div>
              {projectReportDetails.length == 0 && (
                <div
                  style={{
                    display: "flex",
                    flex: "1",
                  }}
                >
                  {" "}
                </div>
              )}
            </div> */}

            <div
              className="sponsership_details_display"
              style={
                lng === "arab"
                  ? {
                      direction: "rtl",
                      width: "100%",
                    }
                  : { width: "100%" }
              }
            >
              <div className="onColumn projectsSec">
                <div className="sponsership_info_pdf_wraper">
                  <div
                    className="sponsership_info_title"
                    style={{
                      display: "flex",
                    }}
                  >
                    <div> </div>
                    <p>{t("Project Details")}</p>
                    {/* {displayedItem != "" && projectReportDetails.length > 0 ? (
                      <i
                        className="fas fa-file-pdf"
                        onClick={sendReportByEmail}
                        style={{
                          cursor: "pointer",
                        }}
                      ></i>
                    ) : (
                      <div></div>
                    )} */}
                  </div>
                  <div className="reportDetails">
                    {selectedProject != null ? (
                      <>
                        <div className="reportLeft">
                          <ReportDetailsRow
                            customKey="Project No"
                            customValue={selectedProject.projectID}
                          />
                          <ReportDetailsRow
                            customKey="Project Name"
                            customValue={selectedProject.projectTypeName}
                          />
                          <ReportDetailsRow
                            customKey="Total Cost"
                            customValue={selectedProject.totalCost}
                          />
                        </div>
                        <div className="reportRight">
                          <ReportDetailsRow
                            customKey="Country Name"
                            customValue={selectedProject.countryName}
                          />
                          <ReportDetailsRow
                            customKey="Project Year"
                            customValue={selectedProject.projectYear}
                          />
                          <ReportDetailsRow
                            customKey="Work in Percentage"
                            customValue={selectedProject.percentCompleted}
                          />
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {t("Please select a report to view it's details")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchProjects;
