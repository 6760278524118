import React from "react";
import "./Auth.css";
import LoginSign from "./loginSignPage/loginSign";
import { Route, Switch } from "react-router-dom";
import NeedToOTP from "./loginSignPage/uNeedToOTP/NeedToOTP";
import ResetPassword from "./loginSignPage/resetPAssword/ResetPassword";
import GetAccount from "./loginSignPage/getAccount/GetAccount";

function Auth() {
  return (
    <div className="App">
      <Switch>
        <Route path="/auth/" component={LoginSign} exact />
        <Route path="/auth/needToOTP" component={NeedToOTP} />
        <Route path="/auth/reset-password" component={ResetPassword} />
        <Route path="/auth/get-account" component={GetAccount} />
      </Switch>
    </div>
  );
}

export default Auth;
