import { useState, useEffect } from "react";
import "./TransactionHistory.css";
import TransactionList from "./TransactionDisplay/TransactionList";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getSPOrphonsList } from "../../actions/user";
import ShareHeader from "../LoaclComponents/shareHeader/ShareHeader";
import LoadingModal from "../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import SortIcon from "../../assets/sort.svg"
import FilterModalTransaction from "./FilterModalTransaction/FilterModalTransaction";
import SortModalTransaction from "./SortModalTransaction/SortMedalTransaction";
import Fuse from "fuse.js";
import moment from 'moment';

const TransactionHistory = (props) => {
  const sponID = useSelector((state) => {return state.auth.extraSponID;});
  const myorphons = useSelector((state) => {return state.auth.myorphons;});
  const allTransactions = useSelector((state) => {
    return state.auth.transactions});
  const [filteredOrphans, setFilteredOrphans] = useState([])
  const [showfilterModal, setShowFilterModal] = useState(false)
  const [showSortModal, setShowSortModal] = useState(false)
  const [itemSelectedList, setItemSelectedList] = useState(props?.history?.location?.state?.itemSelectedMode ?? [])
  const [isItemSelectedMode, setIsItemSelectedMode] = useState(props?.history?.location?.state?.itemSelectedMode)
  const [initialSet, setInitialSet] = useState(false)
  const [transactions, setTransactions] = useState([])
  useEffect(() => {
    if(myorphons && !filteredOrphans.length)
    {
      setFilteredOrphans(myorphons)
    }
  }, [myorphons])

  useEffect(() => {

  }, [filteredOrphans])
    function sponserId() {
      if (sponID) return sponID;
      return getUserInfo();
    }
    function getUserInfo() {
      if (userInfo?.Results && userInfo?.Results.length > 0 && userInfo?.Results[0]) {
        return userInfo?.Results[0];
      }
    }
    const updateOrphans = (orphans) => {
      // setFilteredOrphans(orphans)
      setTransactions(allTransactions.filter(item => orphans.map((item) => {return item.orp_id}).includes(item?.orphan_id)))
      setShowFilterModal(false)
    }

    const sortOrphans = (type, order) => {
      if(type === "price")
      {
        setTransactions(transactions.sort(function(a, b) {
          return a?.transaction_amount - b?.transaction_amount;
        }))
      }
      else if(type === "name")
        {
          setTransactions(transactions.sort((a, b) => a.transaction_amount.localeCompare(b.transaction_amount)))
        }
        else if(type === "date")
        {
          setTransactions(transactions.sort((a, b) => structuredClone(new Date(moment(a.transaction_date.split('/')[1] + "/" + a.transaction_date.split('/')[0] + "/" + a.transaction_date.split('/')[2].split(' ')[0], "DD/MM/YYYY"))) - structuredClone(new Date(moment(b.transaction_date.split('/')[1] + "/" + b.transaction_date.split('/')[0] + "/" + b.transaction_date.split('/')[2].split(' ')[0], "DD/MM/YYYY")))))

        }

        if(order === "DESC")
          setTransactions(transactions.reverse())
      setShowSortModal(false)
    }

    useEffect(() => {
      if(allTransactions.length && !initialSet)
      {
        setInitialSet(true)
      if(!itemSelectedList.length && !transactions.length)
        {
          setTransactions(allTransactions)}
          else
        {
          setTransactions(allTransactions.filter(item => itemSelectedList.includes(item?.orphan_id)))
        }
      }
    }, [allTransactions])
    useEffect(() => {
    }, [transactions])
    // const transactions = useSelector((state) => {
    //   return !isItemSelectedMode?
    //   state.auth.transactions:
    //   state.auth.transactions.filter(item => itemSelectedList.includes(item?.orphan_id));
      
    // });
      const transactionsLoading = useSelector((state) => {
        return state.auth.transactionsLoading;
      });
      const dispatch = useDispatch();
      const userInfo = useSelector((state) => {
        return state.auth.authData;
      });
      const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let mounted = true
  useEffect(()=> {
    window.addEventListener('scroll', () => {
      if (mounted) {
      }
    })
    return () => {
      mounted = false
    }
  }, [])
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {

    if (sponserId()?.sponser_id) {
      dispatch(
        getSPOrphonsList(sponserId()?.sponser_id, lng === "arab" ? "ARA" : "ENG")
        );
      }
      else
      {
        window.location.href = window.location.origin
      }
    }, [sponserId()?.sponser_id]);

    const searchData = async (pattern) => {
      if (!pattern) {
        setTransactions(allTransactions);
        return;
      }
      let fuse = new Fuse(allTransactions, {
        keys: ["orphan_name"],
      });
       let result = await fuse.search(pattern);
       if(!result.length)
       {
        fuse = new Fuse(allTransactions, {
          keys: ["orphan_id"],
          threshold: 0.0
        });
        result = await fuse.search(pattern);
       }
      

      const matches = [];
       await result.forEach(({ item }) => {
          matches.push(item);
        });
        setTransactions(matches);
    };

    return (
      <div
        style={
          lng === "arab"
            ? { direction: "rtl", width: "100%", position:'relative' }
            : { width: "100%", position:'relative' }
        }
      >
                <ShareHeader pageName={t("Transaction History")} noShare={true}/>
                <div className="search-transaction-section">
        <div className="search-form">
        <input           onChange={(e) => searchData(e.target.value)}  className="search-input" type="text" placeholder={t("Search by name or id...")} name="search" />
        <button className="searchButton" type="submit"><i className="fa fa-search"></i></button>
        </div>
        <div className="filter_trigger">
            <button className="filterButton" onClick={() => {setShowFilterModal(true)}}><i className="fas fa-filter"></i></button>
            <span style={{margin:'0', padding: '0'}}>{t("Filter")}</span>
          </div>
          <div className="filter_trigger">
            <button className="filterButton" onClick={() => {setShowSortModal(true)}}><img style={{width: '100%', height: '100%'}} src={SortIcon} /></button>
            <span style={{margin:'0', padding: '0'}}>{t("Sort")}</span>
          </div>
        </div>
       
      
    
  {userInfo && !transactionsLoading && transactions.length > 0?<TransactionList  transactions={transactions} location={props.location} />
        : !transactionsLoading?
        <>No Orphans available. Please go back!</>:<LoadingModal
      show={true}
      dialogClassName="modal-60w"
      backdrop="static"
    />}
    {filteredOrphans.length || showfilterModal?<FilterModalTransaction
        setitemselectedlist={updateOrphans}
        myorphons={filteredOrphans}
        show={showfilterModal}
            dialogClassName="modal-60w"
            backdrop="static"
            onHide={() => {
              setShowFilterModal(false)
            }}  />:null}
            {filteredOrphans.length && showSortModal?<SortModalTransaction
        setitemselectedlist={updateOrphans}
        sortOrphans={sortOrphans}
        myorphons={filteredOrphans}
        show={showSortModal}
            dialogClassName="modal-60w"
            backdrop="static"
            onHide={() => {
              setShowSortModal(false)
            }}  />:null}
  </div>)
};
export default TransactionHistory;