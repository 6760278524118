import React, { useEffect, useState } from "react";
import "./AddPaymentMethod.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import {
  addCardApi,
  encryptPaymentData,
  tokenizeCard,
} from "../../../api/index";
import "react-toastify/dist/ReactToastify.css";
import { toastifyMessage } from "../../../handlers/toastifyMessage";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
import LoadingModal from "../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import Loading from "../../ui/loading";
import { getItemsFromBag } from "../../../actions/BagFav";
import { createPaymentSession, redirectURL } from "../../../api/index";
import ReCAPTCHA from "react-google-recaptcha";
const PaymentMethod = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const history = useHistory();
  const [loadingSwitch, setLoadingSwitch] = useState();
  //card state
  const [cvc, setCvc] = useState("");
  const [LoadingModalState, setLoadingModal] = useState(false);
  const [loadingCard, setLoadingCard] = useState(false);

  const [expiry, setExpiry] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [focus, setFocus] = useState("number");

  const [saveOrNot, SetSaveOrNot] = useState(userLogin ? "save_and_pay" : "");
  const [width, setWidth] = useState(window.innerWidth);
  const [session, setSession] = useState(null);
  // const [myPromise, setMyPromise] = useState(null) ;
  // let _resolve = null;
  // let _reject = null;
  const [_resolve, setResolve] = useState(null);

  const [paymentResponse, setPaymentResponse] = useState(null);
  const [_reject, setReject] = useState(null);

  const [promise, setPromise] = useState(null);
  useEffect(() => {
    setPromise(
      new Promise(function (resolve, reject) {
        setResolve(() => resolve);
        setReject(() => reject);
      })
    );

    async function setUpPaymentSession() {
      setLoadingCard(false);
      if (self === top) {
        var antiClickjack = document.getElementById("antiClickjack");
        // antiClickjack.parentNode.removeChild(antiClickjack);
      } else {
        top.location = self.location;
      }
      let sessionType = props.frompopup
        ? {
            amount: props.paymentamount,
            actionType: "PAY",
          }
        : {
            actionType: "SAVE",
          };
      let res = await createPaymentSession(sessionType);
      setSession(res.data.session.id);
    }
    setUpPaymentSession();
  }, []);
  useEffect(() => {
    async function setupPayment() {
      if (_resolve !== null && _reject !== null && session != null) {
        PaymentSession.configure({
          session: session,
          fields: {
            // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
            card: {
              number: "#card-number",
              securityCode: "#security-code",
              expiryMonth: "#expiry-month",
              expiryYear: "#expiry-year",
              nameOnCard: "#cardholder-name",
            },
          },
          //SPECIFY YOUR MITIGATION OPTION HERE
          frameEmbeddingMitigation: ["javascript"],
          callbacks: {
            initialized: function (response) {
              setLoadingCard(true);
              // HANDLE INITIALIZATION RESPONSE
              //               PaymentSession.setFocus('card.number');

              // PaymentSession.setFocusStyle(["card.number","card.securityCode"], {
              //   borderColor: 'red',
              //   borderWidth: '3px',
              //   fontFamily: 'arab_font'
              // });
            },
            formSessionUpdate: function (response) {
              setPaymentResponse(response);
              // HANDLE RESPONSE FOR UPDATE SESSION
              if (response.status) {
                if ("ok" == response.status) {
                  _resolve(response);

                  //check if the security code was provided by the user
                  if (response.sourceOfFunds.provided.card.securityCode) {
                    console.log("Security code was provided.");
                  }

                  //check if the user entered a Mastercard credit card
                  if (
                    response.sourceOfFunds.provided.card.scheme == "MASTERCARD"
                  ) {
                    console.log("The user entered a Mastercard credit card.");
                  }
                } else if ("fields_in_error" == response.status) {
                  _reject(response);

                  console.log("Session update failed with field errors.");
                  if (response.errors.cardNumber) {
                    console.log("Card number invalid or missing.");
                  }
                  if (response.errors.expiryYear) {
                    console.log("Expiry year invalid or missing.");
                  }
                  if (response.errors.expiryMonth) {
                    console.log("Expiry month invalid or missing.");
                  }
                  if (response.errors.securityCode) {
                    console.log("Security code invalid.");
                  }
                } else if ("request_timeout" == response.status) {
                  console.log(
                    "Session update failed with request timeout: " +
                      response.errors.message
                  );
                } else if ("system_error" == response.status) {
                  console.log(
                    "Session update failed with system error: " +
                      response.errors.message
                  );
                }
              } else {
                console.log("Session update failed: " + response);
              }
              setPromise(
                new Promise(function (resolve, reject) {
                  setResolve(() => resolve);
                  setReject(() => reject);
                })
              );
            },
          },
          interaction: {
            displayControl: {
              formatCard: "EMBOSSED",
              invalidFieldCharacters: "REJECT",
            },
          },
        });
      }
      // setTimeout(() => {setLoadingCard(true)}, 1000)
    }
    setupPayment();
  }, [_reject, _resolve, session]);
  async function pay() {
    PaymentSession.updateSessionFromForm("card");

    // await PaymentSession.updateSessionFromForm('card');
  }
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    if (!userLogin && !props.frompopup) {
      history.push("/");
    }
  });
  const [captchaValue, setCaptchaValue] = useState();
  const handleAddCards = async () => {
    promise
      .then(async () => {
        if (saveOrNot === "save_and_pay") {
          let sessionData = {
            session: session,
            amount: props.paymentamount,
            actionType: "PAY_AND_SAVE",
            isUser: userLogin ? true : false,
            userEmail: userLogin ? userLogin.Results[0]?.email : null,
            captcha:captchaValue

          };
          await props.handleRegularPayment_Inner("save", sessionData);
        } else {
          let sessionData = {
            session: session,
            amount: props.paymentamount,
            actionType: props.noOption ? "PAY_AND_SAVE" : "PAY",
            captcha:captchaValue
          };
          await props.handleRegularPayment_Inner("noSave", sessionData);
        }
      })
      .catch((e) => {
        if (e.status === "fields_in_error") {
          toastifyMessage(
            "Please make sure all fields are correct",
            undefined,
            "error"
          );
        } else toastifyMessage("Unknown error occured", undefined, "error");
      });
    await pay();
  };
  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };
  const addPaymentMethod = async () => {
    // setLoadingModal(true);
    promise
      .then(async () => {
        let id = "TOKEN" + Date.now();
        let sessionData = {
          session: session,
          mco: id,
          amount: 2,
          actionType: "SAVE",
          redirectUrl: window.location.origin + "/payment-method",
          userEmail: userLogin?.Results[0]?.email,
        };
        let encrypted_data = await encryptPaymentData(sessionData);

        var form = document.createElement("form");
        var element1 = document.createElement("input");

        form.method = "POST";
        form.action = `${redirectURL}/AuthGetData.aspx`;

        element1.value = encrypted_data.data.data;
        element1.name = "sessionData";
        form.appendChild(element1);
        // window.location.replace(`${redirectURL}/Auth.aspx?sessionData=` + encodeURIComponent(encrypted_data.data.data));
        document.body.appendChild(form);

        form.submit();
      })
      .catch((e) => {
        if (e.status === "fields_in_error") {
          toastifyMessage(
            "Please make sure all fields are correct",
            undefined,
            "error"
          );
        } else toastifyMessage("Unknown error occured", undefined, "error");
      });
    await pay();
  };
  const [captchaFilled, setCaptchaFilled] = useState(false);
  const [captchaExpired, setCaptchaExpired] = useState(false);

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaFilled(true);
      setCaptchaExpired(false);
      setCaptchaValue(value)
    } else {
      setCaptchaFilled(false);
    }
  };
  return (
    <div
      className="payment-method"
      style={
        lng === "arab" ? { direction: "rtl", width: "100%" } : { width: "100%" }
      }
    >
      {loadingSwitch ? (
        <Loading height="500px" />
      ) : (
        <React.Fragment>
          {props.frompopup ? null : (
            <div
              className="submit_requ_header"
              style={{
                display: props.frompopup ? "flex" : null,
                marginTop: props.frompopup ? "0px" : null,
              }}
            >
              <h3 style={{ color: "gray" }}>{t("Add New Card")}</h3>
            </div>
          )}
          {props.frompopup ? null : (
            <ShareHeader noShare={true} pageName={t("Add New Card")} />
          )}
          <div
            id="antiClickjack"
            className="paymentBody paymentBodyHere"
            style={
              props.frompopup
                ? {
                    width: "100%",
                    padding: "10px",
                    backgroundColor: "white",
                    marginTop: "0px",
                    borderBottomRightRadius: "15px",
                    borderBottomLeftRadius: "15px",
                    display: "none !important",
                  }
                : { width: "90%", padding: "10px", display: "none !important" }
            }
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                direction: "ltr",
              }}
              className="payment_numbers"
            >
              {/* <Cards
                cvc={cvc}
                expiry={expiry}
                focused={focus}
                name={name}
                number={number}
              /> */}
            </div>
            <div
              className="addPaymentMethodForm addPaymentMethodFormHere"
              style={{ display: !loadingCard ? "none" : "inline" }}
            >
              <div style={{ width: "100%" }}>
                <div>{t("Card Number")}</div>
                <div style={{ direction: "ltr" }}>
                  <input
                    type="text"
                    id="card-number"
                    className="input-field payment_numbers"
                    title="card number"
                    aria-label="enter your card number"
                    value=""
                    tabIndex="1"
                    readOnly

                    // name="number"
                    // pattern="[0-9]*"
                    // value={number}
                    // style={{ width: "-moz-available" }}
                    // onChange={(e) => setNumber(e.target.value.replace(/\D/, ""))}
                    // onFocus={handleInputFocus}
                    // maxLength="16"
                    // required
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div>{t("Cardholder Name")}</div>
                <input
                  aria-label="enter name on card"
                  tabIndex="2"
                  readOnly
                  className="input-field"
                  id="cardholder-name"
                  type="text"
                  // name="name"
                  // value=""
                  value={name}
                  // style={{ width: "-moz-available" }}
                  // onChange={(e) => setName(e.target.value)}
                  // onFocus={handleInputFocus}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit,minmax(100px, 1fr))",
                  gap: "20px",
                  width: "100%",
                }}
              >
                <div>
                  <div
                    className="payment_numbers"
                    style={{ fontFamily: "english_font" }}
                  >
                    {"MM"}
                  </div>
                  <input
                    aria-label="two digit expiry month"
                    tabIndex="3"
                    readOnly
                    className="input-field"
                    id="expiry-month"
                    type="text"
                    // value=""
                    // name="expiryMonth"
                    value={expiryMonth}
                    // style={{ width: "-moz-available" }}
                    // onChange={(e) =>
                    //   setExpiryMonth(e.target.value.replace(/\D/, ""))
                    // }
                    // onFocus={handleInputFocus}
                    // maxLength="2"
                  />
                </div>
                <div>
                  <div
                    className="payment_numbers"
                    style={{ fontFamily: "english_font" }}
                  >
                    {"YY"}
                  </div>
                  <input
                    aria-label="two digit expiry year"
                    tabIndex="4"
                    readOnly
                    className="input-field"
                    id="expiry-year"
                    type="text"
                    // value=""
                    // name="expiryYear"
                    value={expiryYear}
                    // style={{ width: "-moz-available" }}
                    // onChange={(e) =>
                    //   setExpiryYear(e.target.value.replace(/\D/, ""))
                    // }
                    // onFocus={handleInputFocus}
                    // maxLength="2"
                  />
                </div>
                <div>
                  <div
                    className="payment_numbers"
                    style={{ fontFamily: "english_font" }}
                  >
                    {"CVC"}
                  </div>
                  <input
                    className="input-field"
                    aria-label="three digit CCV security code"
                    tabIndex="4"
                    readOnly
                    id="security-code"
                    type="text"
                    // value=""
                    // name="cvc"
                    // style={{ width: "-moz-available" }}
                    value={cvc}
                    // onChange={(e) => setCvc(e.target.value.replace(/\D/, ""))}
                    // onFocus={handleInputFocus}
                    // maxLength="3"
                  />
                </div>
              </div>
              {props.frompopup ? (
                <div
                  onClick={() =>
                    props.noOption
                      ? null
                      : saveOrNot === "save_and_pay"
                      ? SetSaveOrNot("save_and_pay_one_time")
                      : SetSaveOrNot("save_and_pay")
                  }
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "5px",
                  }}
                >
                  {userLogin ? (
                    <>
                      <div
                        style={{
                          cursor: "pointer",
                          width: "16px",
                          height: "16px",
                        }}
                        className={
                          saveOrNot === "save_and_pay"
                            ? "check_radio_selected"
                            : "check_radio_unselected"
                        }
                      ></div>
                      <label style={{ minHeight: "auto" }}>
                        {t("Save this method for future payments")}{" "}
                      </label>
                    </>
                  ) : null}
                </div>
              ) : null}
              {props.frompopup ? (
                <div
                  style={{
                    marginBottom: "5px",
                    margin: "5px",
                  }}
                >
                  <ReCAPTCHA
                    sitekey="6LfBdbIpAAAAAMiUQVfnmX_Ld5RG3ix-Sz86a1X-"
                    onChange={handleCaptchaChange}
                  />
                </div>
              ) : null}
              {props.frompopup ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    gap: "7px",
                  }}
                >
                  <button
                    id="payButton"
                    type="button"
                    className="addPaymentCardBtn2 addPaymentCardBtnHere"
                    style={{ border: "none" }}
                    onClick={handleAddCards}
                    disabled={!captchaFilled}
                    // onClick={() => pay()}
                  >
                    {t("Pay Now")}
                  </button>
                  <label
                    style={{
                      minHeight: "auto",
                      textAlign: "center",
                      color: "#ee4236",
                      direction: lng === "arab" ? "rtl" : "ltr",
                      padding: "5px",
                    }}
                    className="label_to_beSmall"
                  >
                    {props.noOption
                      ? "*" +
                        t(
                          "For recurring payments, The same amount will be automatically deducted on the same date"
                        )
                      : null}
                  </label>
                </div>
              ) : (
                <button
                  type="button"
                  className="addPaymentCardBtn2 addPaymentCardBtnHere smallBtn_smallDevice"
                  style={{
                    border: "none",
                    minHeight: width > 900 ? "55px" : "35px",
                  }}
                  onClick={() => addPaymentMethod()}
                >
                  {t("Save")}
                </button>
              )}
            </div>
            <div style={{ display: loadingCard ? "none" : "inline" }}>
              <Loading height="250px" />
            </div>

            <LoadingModal
              show={LoadingModalState}
              dialogClassName="modal-60w"
              backdrop="static"
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
export default PaymentMethod;
