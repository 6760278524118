import React, { useEffect, useState } from "react";
import './Partener.css'
import { useTranslation } from "react-i18next";
import Loading from '../ui/loading'
import ShareHeader from "../LoaclComponents/shareHeader/ShareHeader";
import { toastifyMessage } from "../../handlers/toastifyMessage";
import { getParteners } from '../../api'
import { useHistory } from "react-router";

const Partenter = () => {
  const { t } = useTranslation();
  const history = useHistory();

  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [loadingSwitch, setLoadingSwitch] = useState(true);
  const [parteners, setParteners] = useState([]);
  const [sections, setSections] = useState([]);
  useEffect(() => {
    getParteners(lng === 'arab' ? 1 : 2)
      .then((res) => {
        if (res.data.status) {
            setLoadingSwitch(false)
            setParteners(res.data.data.partners)
            setSections(res.data.data.sections)
        } else {
            setLoadingSwitch(false)
          toastifyMessage("There is no order with this MCO", undefined, "error");
        }
      })
      .catch((e) => {
        let number = Object.keys(e).length;
        if (number > 0) {
          setLoadingSwitch(false)
          toastifyMessage("Something went wrong...!", undefined, "error");
        }
      })
      .finally(() => {
        setLoadingSwitch(false);
      });
  }, [history]);
    return (
        <div className="ourLoactions">
            <ShareHeader pageName={t("Our Partners")} noShare={true}/>
            {sections?.map((section, i) => {
              return <>
              <div key={i} className="partner_section">
              <h3 style={{ color: "gray" }}>{`Our ${section}`}</h3>
            </div>
            {loadingSwitch?
            <Loading height='85vh'/>:
            <div className="partners_wraper" style={lng==='arab'? {direction:'rtl'}:null}>
                {parteners?.filter((item) => item.section === section).map((item, i) => 
                    <a href={item.url} target='_blank' key={i} className="partners_item">
                        <img src={item.imagePath} alt={item.title} className='partner_img'/>
                    </a>
                )}
            </div>   
        }</>
            })}
            
            
        </div>
    )
}

export default Partenter
