import React, { useEffect, useState } from 'react'
import './CountryProComp.css'
import { useTranslation } from "react-i18next";
import Default from '../../../../../../assets/defaultFlag.jpg'

const CountryProComp = (props) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    let checkBoxStyle = lng === 'arab' ? 'rightSign_btn_arab--active' : 'rightSign_btn--active';
    const [imgSrc, setImgSrc] = useState(props.ImagePath)
    useEffect(() => {
        setImgSrc(props.ImagePath)
    }, [props.ImagePath])
    // useEffect(() => {
    //     if (props.selected === props.CountryId) {
    //         // document.getElementById('country_' + props.CountryId).style.border = '1px solid #ee4236';
    //         // document.getElementById('rightSign_btn_off_' + props.CountryId).className = checkBoxStyle;

    //         // document.getElementById('rightSign_btn_off_' + props.CountryId).className = 'rightSign_btn--active';
    //     } else {
    //         // document.getElementById('country_' + props.CountryId).style.border = 'none';
    //         // document.getElementById('rightSign_btn_off_' + props.CountryId).className = 'rightSign_btn';
    //     }
    // }, [props.selected, props.CountryId])
    const handleClick = (str) => {
        props.setSelectedCountry(str)
        if (props.fromOrphan) {
            props.setOrphansData({
                orphanId: props.orphansData.orphanId,
                orphanImg: props.orphansData.orphanImg,
                orphanName: props.orphansData.orphanName,
                orphanCountryId: props.CountryId,
                orphanCountryName: props.Name_En,
                orphanCountryImg: props.ImagePath,
                paymentWay: props.orphansData.paymentWay,
                years: props.orphansData.years,
                donationAmountFromUser: props.orphansData.donationAmountFromUser
            })
        } else {
            /*  props.setProjectData({
              projectId: props.projectData.projectId,
              projectName: props.projectData.projectName,
              projectImg: props.projectData.projectImg,
              projectAmount: props.projectData.projectAmount,
              countryName: props.Name_En,
              countryId: props.CountryId,
              countryImg: props.ImagePath,
              projectYear: new Date().getFullYear()
          })*/
        }

    }
    return (
        <div className='country_project_comp'>
            <span style={lng === 'arab' ? { marginRight: '0px', marginLeft: '-2px' } : null} id={`rightSign_btn_off_${props.CountryId}`} className={props.selected === props.CountryId? checkBoxStyle: 'rightSign_btn'}><i className="fas fa-check"></i></span>
            <button style={{border: props.selected === props.CountryId? '1px solid #ee4236':'unset'}} id={`country_${props.CountryId}`} onClick={() => handleClick(props.CountryId)} className='counteies_flags_wraper'>
                <img id={`country_img_${props.CountryId}`} src={imgSrc} onError={() => setImgSrc(Default)} alt={props.Name_En} className='country_flags_img' />
                <label style={{ width: '100%' }} id={`label_${props.CountryId}`}>{props.Name_En}</label>
            </button>
        </div>
    )
}

export default CountryProComp