/* eslint-disable */
import {
  AUTH,
  FETCH_VLN_REGISTER_PROGRESS,
  FETCH_ALL_DONATIONSSUMMARY,
  FETCH_ALL_TRANSACTIONSSUMMARY,
  FETCH_SPONSORED_FOLLOW_UPS,
  FETCH_ALL_NOTIFICATIONS,
  FETCH_ALL_ORPHONS,
  DELETE_CARD,
  ALL_ORPHONS_LATE_PAYMENTS,
  ALL_ORPHON_LATE_PAYMENTS_ERROR,
  ALL_ORPHON_LATE_PAYMENTS_LOADING,
  ALL_ORPHON_LATE_PAYMENTS,
  SET_SPONSER_ACCOUNT,
  CLEAR_SPONSER_ACCOUNT,
  ADD_CARD,
  DONATIONS_LOADING,
  DONATIONS_LOADING_FALSE,
  TRANSACTIONS_LOADING,
  TRANSACTIONS_LOADING_FALSE,
  LOAD_NOTIFICATIONS,
  NOTIFICATIONS_LOADED,
  LOAD_SPONSOR,
  SPONSOR_LOADED,
  LOAD_SPONSOR_FOLLOWUPS,
  SPONSOR_FOLLOWUPS_LOADED,
} from "../constants/actionTypes";
import * as api from "../api";
import * as account from "../api/account";
import { addToBag, addToFavorites } from "../actions/BagFav";
import jwt_decode from "jwt-decode";
import { toastifyMessage } from "../handlers/toastifyMessage";
import i18n from "i18next";
import DonationFinalPopUp from '../components/Auth/UAEPASSAuthPopup/UAEAuthPopup/UAEPASSAuthOptions'



export const signIn =
  (loginBody, setLoadingModalShow, setContentForLogin, history, fromState) =>
  async (dispatch) => {
    try {
      const fields = {
        main: "To sign in, enter missing fields",
        p1: "E-mail, Mobile or File Number",
        p2: "Password",
      };
      console.log("history", fromState);
      console.log("loginBody", loginBody);
      if (!loginBody.user_id && !loginBody.password) {
        toastifyMessage(
          undefined,
          undefined,
          "warning",
          <div>
            {i18n.t(fields.main)}:
            <br /> - {i18n.t(fields.p1)}.
            <br /> - {i18n.t(fields.p2)}.
          </div>
        );
        setLoadingModalShow(false);
      } else if (!loginBody.user_id) {
        toastifyMessage(
          undefined,
          undefined,
          "warning",
          <div>
            {i18n.t(fields.main)}:
            <br /> - {i18n.t(fields.p1)}.
          </div>
        );
        setLoadingModalShow(false);
      } else if (!loginBody.password) {
        toastifyMessage(
          undefined,
          undefined,
          "warning",
          <div>
            {i18n.t(fields.main)}:
            <br /> - {i18n.t(fields.p2)}.
          </div>
        );
        setLoadingModalShow(false);
      } else {

        console.log("loginBodyNEW", loginBody);
        try {
          var userData = await api.signIn(loginBody)
          userData={data:{Results:[userData]}}
          await dispatch({ type: AUTH, data: userData.data });
          afterLogin(userData);
          console.log("userData", userData);
        } catch (error) {
          console.log("error", error);
          toastifyMessage("Login Failed!", error, "warning");
        }
        finally
        {
          setLoadingModalShow(false);
        }
      //  setLoadingModalShow(false);
        // let userData1 = await api.whoAmI()
        // console.log("userData", userData1);
        // console.log("userData", authToken);
      }
      if (
        (userData.data.Results === "Wrong Password") |
        (userData.data.Results === "User Not Found") |
        (userData.data.Results === "Incorrect email/password is entered") |
        (userData.data.Results === "OTP not Verified")
      ) {
        setLoadingModalShow(false);
       
        const response = {
     //     error: `${userData.data["Results"]}`,
          error: `${userData.data.result}`,
          message: "Please, check your credentials and try again.",
        };
        console.log("response", response)
        toastifyMessage("Login Failed!", response, "warning");
        if (userData.data.Results === "OTP not Verified") {
          history.push(`/auth/needToOTP`, {
            email: loginBody.user_id,
            from: "signIn",
          });
        }
      } else { 
       
      }
    } catch (error) {
      let number = Object.keys(error).length;
      if (number > 0) {
        setLoadingModalShow(false);
        toastifyMessage("Something went wrong...!", undefined, "error");
      }
    }
  };
const afterLogin=async (userData)=>{ 
  let currentProfile = JSON.parse(localStorage.getItem("profile"));
  let lng = JSON.parse(localStorage.getItem("language")).lang;
  if (currentProfile) {
    let woleBag = [];
    let wholeFav = [];
    for (let i = 0; i < currentProfile.cart.donations.length; i++) {
      woleBag.push({
        donID: currentProfile.cart.donations[i]?.donationId,
        userName: userData.data.Results[0]?.name_a,
        userMob: userData.data.Results[0]?.mobile_no,
        userEmail: userData.data.Results[0]?.email,
        itemType: 0,
        amount: currentProfile.cart.donations[i]?.amount,
        recuring: "N",
        cart_serial: null,
        remarks: currentProfile.cart.donations[i]?.remarks,

      });
    }
    for (let i = 0; i < currentProfile.cart.humanCases.length; i++) {
      woleBag.push({
        donID: "158",
        userName: userData.data.Results[0]?.name_a,
        userMob: userData.data.Results[0]?.mobile_no,
        userEmail: userData.data.Results[0]?.email,
        itemType: 4,
        amount: currentProfile.cart.humanCases[i]?.donationAmountFromUser,
        cart_serial: null,
        humanCase: currentProfile.cart.humanCases[i]?.humanId,
        humanYear: currentProfile.cart.humanCases[i]?.caseYear,
      });
    }
    for (let i = 0; i < currentProfile.cart.orphans.length; i++) {
      woleBag.push({
        donID: "1",
        userName: userData.data.Results[0]?.name_a,
        userMob: userData.data.Results[0]?.mobile_no,
        userEmail: userData.data.Results[0]?.email,
        itemType: "1",
        amount: currentProfile.cart.orphans[i]?.baseAmount,
        recuring: currentProfile.cart.orphans[i]?.paymentWay,
        cart_serial: null,
        orpTypeID: currentProfile.cart.orphans[i]?.orphanId,
        workPlaceID: currentProfile.cart.orphans[i]?.workPlaceID,
        country_id: currentProfile.cart.orphans[i]?.orphanCountryId,
        month_count:
          currentProfile.cart.orphans[i]?.paymentWay === "N"
            ? currentProfile.cart.orphans[i]?.years > 4
              ? currentProfile.cart.orphans[i]?.years
              : currentProfile.cart.orphans[i]?.years * 12
            : 1,
      });
    }
    for (let i = 0; i < currentProfile.cart.projects.length; i++) {
      woleBag.push({
        donID: "8",
        userName: userData.data.Results[0]?.name_a,
        userMob: userData.data.Results[0]?.mobile_no,
        userEmail: userData.data.Results[0]?.email,
        itemType: "2",
        amount: currentProfile.cart.projects[i]?.projectAmount,
        cart_serial: null,
        workPlaceID: currentProfile.cart.orphans[i]?.workPlaceID,
        prj_name: currentProfile.cart.projects[i]?.projectName,
        projectYear: currentProfile.cart.projects[i]?.projectYear,
        template_id: currentProfile.cart.projects[i]?.projectId,
        country_id: currentProfile.cart.projects[i]?.countryId,
      });
    }
    for (let i = 0; i < currentProfile.cart.sukuks.length; i++) {
      woleBag.push({
        donID: currentProfile.cart.sukuks[i]?.sukId,
        userName: userData.data.Results[0]?.name_a,
        userMob: userData.data.Results[0]?.mobile_no,
        userEmail: userData.data.Results[0]?.email,
        itemType: 0,
        amount: currentProfile.cart.sukuks[i]?.amount,
        recuring: currentProfile.cart.sukuks[i]?.donationFreq,
        cart_serial: null,
      });
    }
    //----------Move Favorites-----------
    for (let i = 0; i < currentProfile.liked.donations.length; i++) {
      wholeFav.push({
        email: userData.data.Results[0]?.email,
        dontypeID: currentProfile.liked.donations[i]?.donationId,
        projectID: "",
        orptypeID: "",
        projectYear: "",
        countryID: "",
      });
    }
    for (let i = 0; i < currentProfile.liked.orphans?.length; i++) {
      wholeFav.push({
        email: userData.data.Results[0]?.email,
        dontypeID: "",
        projectID: "",
        orptypeID: currentProfile.liked.orphans[i]?.orphanId,
        projectYear: "",
        countryID: currentProfile.liked.orphans[i]?.orphanCountryId,
      });
    }
    for (let i = 0; i < currentProfile.liked.projects.length; i++) {
      wholeFav.push({
        email: userData.data.Results[0]?.email,
        dontypeID: "",
        projectID: currentProfile.liked.projects[i]?.projectId,
        orptypeID: "",
        projectYear: currentProfile.liked.projects[i]?.projectYear,
        countryID: "",
      });
    }
    for (let i = 0; i < currentProfile.liked.sukuks.length; i++) {
      wholeFav.push({
        email: userData.data.Results[0]?.email,
        dontypeID: currentProfile.liked.sukuks[i]?.sukId,
        projectID: "",
        orptypeID: "",
        projectYear: "",
        countryID: "",
      });
    }
    await dispatch(
      addToBag({
        cart: woleBag.slice(0),
        language: lng === "arab" ? 1 : 2,
      })
    );
    await dispatch(
      addToFavorites(lng === "arab" ? 1 : 2, wholeFav, "array")
    );
  }
  localStorage.removeItem("profile");
  localStorage.removeItem("visitorDetails");
  if (fromState === "volunteer") {
    window.open("/volunteer/vln-registration", "_self");
  } else if (fromState === "Loacal Aid") {
    window.open("/localAid/setup-information", "_self");
  } else {
    setLoadingModalShow(false);
    window.open("/profile", "_self");
  }
}
//Social sign in part
export const signInWithGoogle =
  (
    accessToken,
    loginBody,
    history,
    setLoadingModalShow,
    fromState,
    completeInfo,
    lodaingSwitch,
    setWait
  ) =>
  async (dispatch) => {
    try {
      var userData = await api.signInWithGoogle1(accessToken);

      // old code
      // var userData = await api.signInWithSocial(
      //   loginBody.email,
      //   loginBody.googleId,
      //   loginBody.name,
      //   "GOOGLE"
      // );
  
      // let res = { Results: [userData.data.Results], loginType: "GOOGLE" };
      userData = {
        data: {
          Results: [userData],
          loginType: "GOOGLE"
        }
      }
      console.log("res", userData);
      await dispatch({ type: AUTH, data: userData.data });
      afterGoogleLogin(userData);
      console.log("userData", userData);

    } catch (error) {
      let number = Object.keys(error).length;
      if (number > 0) {
        setLoadingModalShow(false);
        console.log("error", error);
       toastifyMessage("Something went wrong...!", undefined, "error");
      }
    }
    finally {
      setLoadingModalShow(false);
    }
  };

  const afterGoogleLogin = async (userData) => {
    let currentProfile = JSON.parse(localStorage.getItem("profile"));
    console.log("currentProfile", currentProfile);
    let lng = JSON.parse(localStorage.getItem("language")).lang;
    console.log("lng", lng);
    if (currentProfile) {
      let woleBag = [];
      let wholeFav = [];
      for (let i = 0; i < currentProfile.cart.donations.length; i++) {
        woleBag.push({
          donID: currentProfile.cart.donations[i]?.donationId,
          userName: userData.data.Results?.name_a,
          userMob: userData.data.Results?.mobile_no,
          userEmail: userData.data.Results?.email,
          itemType: 0,
          amount: currentProfile.cart.donations[i]?.amount,
          recuring: "N",
          cart_serial: null,
          remarks: currentProfile.cart.donations[i]?.remarks,
        });
      }
      for (let i = 0; i < currentProfile.cart.humanCases.length; i++) {
        woleBag.push({
          donID: "158",
          userName: userData.data.Results?.name_a,
          userMob: userData.data.Results?.mobile_no,
          userEmail: userData.data.Results?.email,
          itemType: 4,
          amount: currentProfile.cart.humanCases[i]?.donationAmountFromUser,
          cart_serial: null,
          humanCase: currentProfile.cart.humanCases[i]?.humanId,
          humanYear: currentProfile.cart.humanCases[i]?.caseYear,
        });
      }
      for (let i = 0; i < currentProfile.cart.orphans.length; i++) {
        woleBag.push({
          donID: "1",
          userName: userData.data.Results?.name_a,
          userMob: userData.data.Results?.mobile_no,
          userEmail: userData.data.Results?.email,
          itemType: "1",
          amount: currentProfile.cart.orphans[i]?.baseAmount,
          recuring: currentProfile.cart.orphans[i]?.paymentWay,
          cart_serial: null,
          orpTypeID: currentProfile.cart.orphans[i]?.orphanId,
          workPlaceID: currentProfile.cart.orphans[i]?.workPlaceID,
          country_id: currentProfile.cart.orphans[i]?.orphanCountryId,
          month_count:
            currentProfile.cart.orphans[i]?.paymentWay === "N"
              ? currentProfile.cart.orphans[i]?.years > 4
                ? currentProfile.cart.orphans[i]?.years
                : currentProfile.cart.orphans[i]?.years * 12
              : 1,
        });
      }
      for (let i = 0; i < currentProfile.cart.projects.length; i++) {
        woleBag.push({
          donID: "8",
          userName: userData.data.Results?.name_a,
          userMob: userData.data.Results?.mobile_no,
          userEmail: userData.data.Results?.email,
          itemType: "2",
          amount: currentProfile.cart.projects[i]?.projectAmount,
          cart_serial: null,
          workPlaceID: currentProfile.cart.orphans[i]?.workPlaceID,
          prj_name: currentProfile.cart.projects[i]?.projectName,
          projectYear: currentProfile.cart.projects[i]?.projectYear,
          template_id: currentProfile.cart.projects[i]?.projectId,
          country_id: currentProfile.cart.projects[i]?.countryId,
        });
      }
      for (let i = 0; i < currentProfile.cart.sukuks.length; i++) {
        woleBag.push({
          donID: currentProfile.cart.sukuks[i].sukId,
          userName: userData.data.Results?.name_a,
          userMob: userData.data.Results?.mobile_no,
          userEmail: userData.data.Results?.email,
          itemType: 0,
          amount: currentProfile.cart.sukuks[i]?.amount,
          recuring: currentProfile.cart.sukuks[i]?.donationFreq,
          cart_serial: null,
        });
      }
      //----------Move Favorites-----------
      for (let i = 0; i < currentProfile.liked.donations.length; i++) {
        wholeFav.push({
          email: userData.data.Results?.email,
          dontypeID: currentProfile.liked.donations[i]?.donationId,
          projectID: "",
          orptypeID: "",
          projectYear: "",
          countryID: "",
        });
      }
      for (let i = 0; i < currentProfile.liked?.orphans?.length; i++) {
        wholeFav.push({
          email: userData.data.Results?.email,
          dontypeID: "",
          projectID: "",
          orptypeID: currentProfile.liked.orphans[i]?.orphanId,
          projectYear: "",
          countryID: currentProfile.liked.orphans[i]?.orphanCountryId,
        });
      }
      for (let i = 0; i < currentProfile.liked.projects.length; i++) {
        wholeFav.push({
          email: userData.data.Results?.email,
          dontypeID: "",
          projectID: currentProfile.liked.projects[i]?.projectId,
          orptypeID: "",
          projectYear: currentProfile.liked.projects[i]?.projectYear,
          countryID: "",
        });
      }
      for (let i = 0; i < currentProfile.liked.sukuks.length; i++) {
        wholeFav.push({
          email: userData.data.Results?.email,
          dontypeID: currentProfile.liked.sukuks[i]?.sukId,
          projectID: "",
          orptypeID: "",
          projectYear: "",
          countryID: "",
        });
      }
      await dispatch(
        addToBag({ cart: woleBag.slice(0), language: lng === "arab" ? 1 : 2 })
      );
      await dispatch(
        addToFavorites(lng === "arab" ? 1 : 2, wholeFav, "array")
      );
    }
    localStorage.removeItem("profile");
    localStorage.removeItem("visitorDetails");
    if (
      !userData.data.Results.emiratesID ||
      !userData.data.Results.mobile_no ||
      !userData.data.Results.name_a
    ) {
      setWait(true);

      if (fromState === "volunteer") {
        setLoadingModalShow(false);
        toastifyMessage(
          "Please complete your information",
          undefined,
          "warning"
        );
        return completeInfo({
          ...lodaingSwitch,
          open: true,
          goTo: "/volunteer/vln-registration",
        });
        //window.open("/volunteer/vln-registration", "_self");
        //history.push("/volunteer/vln-registration")
      } else if (fromState === "Loacal Aid") {
        setLoadingModalShow(false);
        toastifyMessage(
          "Please complete your information",
          undefined,
          "warning"
        );
        return completeInfo({
          ...lodaingSwitch,
          open: true,
          goTo: "/localAid/setup-information",
        });
        //history.push("/localAid/setup-information")
        //window.open("/localAid/setup-information", "_self");
      } else {
        setLoadingModalShow(false);
        toastifyMessage(
          "Please complete your information",
          undefined,
          "warning"
        );
        return completeInfo({
          ...lodaingSwitch,
          open: true,
          goTo: "/profile",
        });
      }
    }
    if (fromState === "volunteer") {
      window.open("/volunteer/vln-registration", "_self");
      //history.push("/volunteer/vln-registration")
    } else if (fromState === "Loacal Aid") {
      //history.push("/localAid/setup-information")
      window.open("/localAid/setup-information", "_self");
    } else {
      setLoadingModalShow(false);
      window.open("/profile", "_self");
    }
  }

export const signInWithFacebook =
  (
    loginBody,
    history,
    setLoadingModalShow,
    fromState,
    completeInfo,
    lodaingSwitch,
    setWait
  ) =>
  async (dispatch) => {
    try {
      var loginData = await api.getFacebookEmail(loginBody);
      var userData = await api.signInWithSocial(
        loginData.data.email,
        loginData.data.id,
        loginData.data.name,
        "FACEBOOK"
      );
      let currentProfile = JSON.parse(localStorage.getItem("profile"));
      let lng = JSON.parse(localStorage.getItem("language")).lang;
      let res = { Results: [userData.data.Results], loginType: "FACEBOOK" };
      await dispatch({ type: AUTH, data: res });
      if (currentProfile) {
        let woleBag = [];
        let wholeFav = [];
        for (let i = 0; i < currentProfile.cart.donations.length; i++) {
          woleBag.push({
            donID: currentProfile.cart.donations[i]?.donationId,
            userName: userData.data.Results?.name_a,
            userMob: userData.data.Results?.mobile_no,
            userEmail: userData.data.Results?.email,
            itemType: 0,
            amount: currentProfile.cart.donations[i]?.amount,
            recuring: "N",
            cart_serial: null,
            remarks: currentProfile.cart.donations[i]?.remarks,

          });
        }
        for (let i = 0; i < currentProfile.cart.humanCases.length; i++) {
          woleBag.push({
            donID: "158",
            userName: userData.data.Results?.name_a,
            userMob: userData.data.Results?.mobile_no,
            userEmail: userData.data.Results?.email,
            itemType: 4,
            amount: currentProfile.cart.humanCases[i]?.donationAmountFromUser,
            cart_serial: null,
            humanCase: currentProfile.cart.humanCases[i]?.humanId,
            humanYear: currentProfile.cart.humanCases[i]?.caseYear,
          });
        }
        for (let i = 0; i < currentProfile.cart.orphans.length; i++) {
          woleBag.push({
            donID: "1",
            userName: userData.data.Results?.name_a,
            userMob: userData.data.Results?.mobile_no,
            userEmail: userData.data.Results?.email,
            itemType: "1",
            amount: currentProfile.cart.orphans[i]?.baseAmount,
            recuring: currentProfile.cart.orphans[i]?.paymentWay,
            cart_serial: null,
            orpTypeID: currentProfile.cart.orphans[i]?.orphanId,
            workPlaceID: currentProfile.cart.orphans[i]?.workPlaceID,
            country_id: currentProfile.cart.orphans[i]?.orphanCountryId,
            month_count:
              currentProfile.cart.orphans[i]?.paymentWay === "N"
                ? currentProfile.cart.orphans[i]?.years > 4
                  ? currentProfile.cart.orphans[i]?.years
                  : currentProfile.cart.orphans[i]?.years * 12
                : 1,
          });
        }
        for (let i = 0; i < currentProfile.cart.projects.length; i++) {
          woleBag.push({
            donID: "8",
            userName: userData.data.Results?.name_a,
            userMob: userData.data.Results?.mobile_no,
            userEmail: userData.data.Results?.email,
            itemType: "2",
            amount: currentProfile.cart.projects[i]?.projectAmount,
            cart_serial: null,
            workPlaceID: currentProfile.cart.orphans[i]?.workPlaceID,
            prj_name: currentProfile.cart.projects[i]?.projectName,
            projectYear: currentProfile.cart.projects[i]?.projectYear,
            template_id: currentProfile.cart.projects[i]?.projectId,
            country_id: currentProfile.cart.projects[i]?.countryId,
          });
        }
        for (let i = 0; i < currentProfile.cart.sukuks.length; i++) {
          woleBag.push({
            donID: currentProfile.cart.sukuks[i].sukId,
            userName: userData.data.Results?.name_a,
            userMob: userData.data.Results?.mobile_no,
            userEmail: userData.data.Results?.email,
            itemType: 0,
            amount: currentProfile.cart.sukuks[i]?.amount,
            recuring: currentProfile.cart.sukuks[i]?.donationFreq,
            cart_serial: null,
          });
        }
        //----------Move Favorites-----------
        for (let i = 0; i < currentProfile.liked.donations.length; i++) {
          wholeFav.push({
            email: userData.data.Results?.email,
            dontypeID: currentProfile.liked.donations[i]?.donationId,
            projectID: "",
            orptypeID: "",
            projectYear: "",
            countryID: "",
          });
        }
        for (let i = 0; i < currentProfile.liked.orphans?.length; i++) {
          wholeFav.push({
            email: userData.data.Results?.email,
            dontypeID: "",
            projectID: "",
            orptypeID: currentProfile.liked.orphans[i]?.orphanId,
            projectYear: "",
            countryID: currentProfile.liked.orphans[i]?.orphanCountryId,
          });
        }
        for (let i = 0; i < currentProfile.liked.projects.length; i++) {
          wholeFav.push({
            email: userData.data.Results?.email,
            dontypeID: "",
            projectID: currentProfile.liked.projects[i]?.projectId,
            orptypeID: "",
            projectYear: currentProfile.liked.projects[i]?.projectYear,
            countryID: "",
          });
        }
        for (let i = 0; i < currentProfile.liked.sukuks.length; i++) {
          wholeFav.push({
            email: userData.data.Results?.email,
            dontypeID: currentProfile.liked.sukuks[i]?.sukId,
            projectID: "",
            orptypeID: "",
            projectYear: "",
            countryID: "",
          });
        }
        await dispatch(
          addToBag({ cart: woleBag.slice(0), language: lng === "arab" ? 1 : 2 })
        );
        await dispatch(
          addToFavorites(lng === "arab" ? 1 : 2, wholeFav, "array")
        );
      }
      localStorage.removeItem("profile");
      localStorage.removeItem("visitorDetails");
      if (
        !userData.data.Results.emiratesID ||
        !userData.data.Results.mobile_no ||
        !userData.data.Results.name_a
      ) {
        setWait(true);
        if (fromState === "volunteer") {
          setLoadingModalShow(false);
          toastifyMessage(
            "Please complete your information",
            undefined,
            "warning"
          );
          return completeInfo({
            ...lodaingSwitch,
            open: true,
            goTo: "/volunteer/vln-registration",
          });
          //window.open("/volunteer/vln-registration", "_self");
          //history.push("/volunteer/vln-registration")
        } else if (fromState === "Loacal Aid") {
          setLoadingModalShow(false);
          toastifyMessage(
            "Please complete your information",
            undefined,
            "warning"
          );
          return completeInfo({
            ...lodaingSwitch,
            open: true,
            goTo: "/localAid/setup-information",
          });
          //history.push("/localAid/setup-information")
          //window.open("/localAid/setup-information", "_self");
        } else {
          setLoadingModalShow(false);
          toastifyMessage(
            "Please complete your information",
            undefined,
            "warning"
          );
          return completeInfo({
            ...lodaingSwitch,
            open: true,
            goTo: "/profile",
          });
        }
      }
      if (fromState === "volunteer") {
        window.open("/volunteer/vln-registration", "_self");
      } else if (fromState === "Loacal Aid") {
        window.open("/localAid/setup-information", "_self");
      } else {
        setLoadingModalShow(false);
        window.open("/profile", "_self");
      }
    } catch (error) {
      let number = Object.keys(error).length;
      if (number > 0) {
        setLoadingModalShow(false);
        toastifyMessage("Something went wrong...!", undefined, "error");
      }
    }
  };

export const signInWithApple =
  (
    loginBody,
    history,
    setLoadingModalShow,
    fromState,
    completeInfo,
    lodaingSwitch,
    setWait
  ) =>
  async (dispatch) => {
    try {
      var result;
      var userName = "";
      var socialID = jwt_decode(loginBody.authorization.id_token).sub;
      var email = jwt_decode(loginBody.authorization.id_token).email;
      if ("user" in loginBody) {
        userName = `${loginBody.user.name.firstName} ${loginBody.user.name.lastName}`;
      }
      result = await api.signInWithSocial(email, socialID, userName, "APPLE");
      let currentProfile = JSON.parse(localStorage.getItem("profile"));
      let lng = JSON.parse(localStorage.getItem("language")).lang;
      let res = { Results: [result.data.Results] };
      await dispatch({ type: AUTH, data: res });
      if (currentProfile) {
        let woleBag = [];
        let wholeFav = [];
        for (let i = 0; i < currentProfile.cart.donations.length; i++) {
          woleBag.push({
            donID: currentProfile.cart.donations[i]?.donationId,
            userName: result.data.Results?.name_a,
            userMob: result.data.Results?.mobile_no,
            userEmail: result.data.Results?.email,
            itemType: 0,
            amount: currentProfile.cart.donations[i]?.amount,
            recuring: "N",
            cart_serial: null,
            remarks: currentProfile.cart.donations[i]?.remarks,
          });
        }
        for (let i = 0; i < currentProfile.cart.humanCases.length; i++) {
          woleBag.push({
            donID: "158",
            userName: result.data.Results?.name_a,
            userMob: result.data.Results?.mobile_no,
            userEmail: result.data.Results?.email,
            itemType: 4,
            amount: currentProfile.cart.humanCases[i]?.donationAmountFromUser,
            cart_serial: null,
            humanCase: currentProfile.cart.humanCases[i]?.humanId,
            humanYear: currentProfile.cart.humanCases[i]?.caseYear,
          });
        }
        for (let i = 0; i < currentProfile.cart.orphans.length; i++) {
          woleBag.push({
            donID: "1",
            userName: result.data.Results?.name_a,
            userMob: result.data.Results?.mobile_no,
            userEmail: result.data.Results?.email,
            itemType: "1",
            amount: currentProfile.cart.orphans[i]?.baseAmount,
            recuring: currentProfile.cart.orphans[i]?.paymentWay,
            cart_serial: null,
            orpTypeID: currentProfile.cart.orphans[i]?.orphanId,
            workPlaceID: currentProfile.cart.orphans[i]?.workPlaceID,
            country_id: currentProfile.cart.orphans[i]?.orphanCountryId,
            month_count:
              currentProfile.cart.orphans[i]?.paymentWay === "N"
                ? currentProfile.cart.orphans[i]?.years > 4
                  ? currentProfile.cart.orphans[i]?.years
                  : currentProfile.cart.orphans[i]?.years * 12
                : 1,
          });
        }
        for (let i = 0; i < currentProfile.cart.projects.length; i++) {
          woleBag.push({
            donID: "8",
            userName: result.data.Results?.name_a,
            userMob: result.data.Results?.mobile_no,
            userEmail: result.data.Results?.email,
            itemType: "2",
            amount: currentProfile.cart.projects[i]?.projectAmount,
            cart_serial: null,
            workPlaceID: currentProfile.cart.orphans[i]?.workPlaceID,
            prj_name: currentProfile.cart.projects[i]?.projectName,
            projectYear: currentProfile.cart.projects[i]?.projectYear,
            template_id: currentProfile.cart.projects[i]?.projectId,
            country_id: currentProfile.cart.projects[i]?.countryId,
          });
        }
        for (let i = 0; i < currentProfile.cart.sukuks.length; i++) {
          woleBag.push({
            donID: currentProfile.cart.sukuks[i].sukId,
            userName: result.data.Results?.name_a,
            userMob: result.data.Results?.mobile_no,
            userEmail: result.data.Results?.email,
            itemType: 0,
            amount: currentProfile.cart.sukuks[i]?.amount,
            recuring: currentProfile.cart.sukuks[i]?.donationFreq,
            cart_serial: null,
          });
        }
        //----------Move Favorites-----------
        for (let i = 0; i < currentProfile.liked.donations.length; i++) {
          wholeFav.push({
            email: result.data.Results?.email,
            dontypeID: currentProfile.liked.donations[i]?.donationId,
            projectID: "",
            orptypeID: "",
            projectYear: "",
            countryID: "",
          });
        }
        for (let i = 0; i < currentProfile.liked.orphans?.length; i++) {
          wholeFav.push({
            email: result.data.Results?.email,
            dontypeID: "",
            projectID: "",
            orptypeID: currentProfile.liked.orphans[i]?.orphanId,
            projectYear: "",
            countryID: currentProfile.liked.orphans[i]?.orphanCountryId,
          });
        }
        for (let i = 0; i < currentProfile.liked.projects.length; i++) {
          wholeFav.push({
            email: result.data.Results?.email,
            dontypeID: "",
            projectID: currentProfile.liked.projects[i]?.projectId,
            orptypeID: "",
            projectYear: currentProfile.liked.projects[i]?.projectYear,
            countryID: "",
          });
        }
        for (let i = 0; i < currentProfile.liked.sukuks.length; i++) {
          wholeFav.push({
            email: result.data.Results?.email,
            dontypeID: currentProfile.liked.sukuks[i]?.sukId,
            projectID: "",
            orptypeID: "",
            projectYear: "",
            countryID: "",
          });
        }
        await dispatch(
          addToBag({ cart: woleBag.slice(0), language: lng === "arab" ? 1 : 2 })
        );
        // await dispatch(addToFavorites(lng === "arab" ? 1 : 2, wholeFav, 'array'));
      }

      localStorage.removeItem("profile");
      localStorage.removeItem("visitorDetails");
      if (!result.data.Results.emiratesID || !result.data.Results.mobile_no || !userData.data.Results.name_a) {
        setWait(true);
        if (fromState === "volunteer") {
          setLoadingModalShow(false);
          toastifyMessage(
            "Please complete your information",
            undefined,
            "warning"
          );
          return completeInfo({
            ...lodaingSwitch,
            open: true,
            goTo: "/volunteer/vln-registration",
          });
          //window.open("/volunteer/vln-registration", "_self");
          //history.push("/volunteer/vln-registration")
        } else if (fromState === "Loacal Aid") {
          setLoadingModalShow(false);
          toastifyMessage(
            "Please complete your information",
            undefined,
            "warning"
          );
          return completeInfo({
            ...lodaingSwitch,
            open: true,
            goTo: "/localAid/setup-information",
          });
          //history.push("/localAid/setup-information")
          //window.open("/localAid/setup-information", "_self");
        } else {
          setLoadingModalShow(false);
          toastifyMessage(
            "Please complete your information",
            undefined,
            "warning"
          );
          return completeInfo({
            ...lodaingSwitch,
            open: true,
            goTo: "/profile",
          });
        }
      }
      if (fromState === "volunteer") {
        window.open("/volunteer/vln-registration", "_self");
      } else if (fromState === "Loacal Aid") {
        window.open("/volunteer/vln-registration", "_self");
      } else {
        setLoadingModalShow(false);
        window.open("/profile", "_self");
      }
    } catch (error) {
      let number = Object.keys(error).length;
      if (number > 0) {
        setLoadingModalShow(false);
        toastifyMessage("Something went wrong...!", undefined, "error");
      }
    }
  };

  export const signInWithUAEPASS =
  (
    loginBody,
    history,
    setLoadingModalShow,
    fromState,
    completeInfo,
    lodaingSwitch,
    setWait,
    setUaeAuth,
    setUAELinkBody,
    stepAPI
  ) =>
  async (dispatch) => {
    try {
      let currentProfile = JSON.parse(localStorage.getItem("profile"));
      let lng = JSON.parse(localStorage.getItem("language")).lang;
      var result;

      let res = { Results: [{...loginBody, login_type: "UAEPASS"}] };
      result = {data: res};
      await dispatch({ type: AUTH, data: res });

      localStorage.removeItem("profile");
      localStorage.removeItem("visitorDetails");
      if (!result.data.Results[0].emiratesID || !result.data.Results[0].mobile_no || !result.data.Results[0].name_a) {

        setLoadingModalShow(false);

        setWait(true);
        if (fromState === "volunteer") {
          setLoadingModalShow(false);
          toastifyMessage(
            "Please complete your information",
            undefined,
            "warning"
          );
          return completeInfo({
            ...lodaingSwitch,
            open: true,
            goTo: "/volunteer/vln-registration",
          });
          //window.open("/volunteer/vln-registration", "_self");
          //history.push("/volunteer/vln-registration")
        } else if (fromState === "Loacal Aid") {
          setLoadingModalShow(false);
          toastifyMessage(
            "Please complete your information",
            undefined,
            "warning"
          );
          return completeInfo({
            ...lodaingSwitch,
            open: true,
            goTo: "/localAid/setup-information",
          });
          //history.push("/localAid/setup-information")
          //window.open("/localAid/setup-information", "_self");
        } else {
          setLoadingModalShow(false);
          toastifyMessage(
            "Please complete your information",
            undefined,
            "warning"
          );
          return completeInfo({
            ...lodaingSwitch,
            open: true,
            goTo: "/profile",
          });
        }
      }
      if (fromState === "volunteer") {
        window.open("/volunteer/vln-registration", "_self");
      } else if (fromState === "Loacal Aid") {
        window.open("/localAid/setup-information", "_self");
      } else {
        setLoadingModalShow(false);
        window.open("/profile", "_self");
      }
    } catch (error) {
      let number = Object.keys(error).length;
      if (number > 0) {
        setLoadingModalShow(false);
        toastifyMessage("Something went wrong...!", undefined, "error");
      }
    }
  };
export const oTPVefi =
  (formData, setLoadingModalShow, setDoneModal, setContent) =>
  async (dispatch) => {
    let currentProfile = JSON.parse(localStorage.getItem("profile"));
    let lng = JSON.parse(localStorage.getItem("language")).lang;
    try {
      let newUserData = await api.otpVerfication(formData);
      if (newUserData.data["status"] === true) {
        if (currentProfile) {
          let woleBag = [];
          let wholeFav = [];
          for (let i = 0; i < currentProfile.cart.donations.length; i++) {
            woleBag.push({
              donID: currentProfile.cart.donations[i].donationId,
              userName: newUserData.data.data?.name_a,
              userMob: newUserData.data.data?.mobile_no,
              userEmail: newUserData.data.data?.email,
              itemType: 0,
              amount: currentProfile.cart.donations[i]?.amount,
              recuring: "N",
              cart_serial: null,
              remarks: currentProfile.cart.donations[i]?.remarks,

            });
          }
          for (let i = 0; i < currentProfile.cart.humanCases.length; i++) {
            woleBag.push({
              donID: "158",
              userName: newUserData.data.data?.name_a,
              userMob: newUserData.data.data?.mobile_no,
              userEmail: newUserData.data.data?.email,
              itemType: 4,
              amount: currentProfile.cart.humanCases[i]?.donationAmountFromUser,
              cart_serial: null,
              humanCase: currentProfile.cart.humanCases[i]?.humanId,
              humanYear: currentProfile.cart.humanCases[i]?.caseYear,
            });
          }
          for (let i = 0; i < currentProfile.cart.orphans.length; i++) {
            woleBag.push({
              donID: "1",
              userName: newUserData.data.data?.name_a,
              userMob: newUserData.data.data?.mobile_no,
              userEmail: newUserData.data.data?.email,
              itemType: "1",
              amount: currentProfile.cart.orphans[i]?.baseAmount,
              recuring: currentProfile.cart.orphans[i]?.paymentWay,
              cart_serial: null,
              orpTypeID: currentProfile.cart.orphans[i]?.orphanId,
              workPlaceID: currentProfile.cart.orphans[i]?.workPlaceID,
              country_id: currentProfile.cart.orphans[i]?.orphanCountryId,
              month_count:
                currentProfile.cart.orphans[i]?.paymentWay === "N"
                  ? currentProfile.cart.orphans[i]?.years > 4
                    ? currentProfile.cart.orphans[i]?.years
                    : currentProfile.cart.orphans[i]?.years * 12
                  : 1,
            });
          }
          for (let i = 0; i < currentProfile.cart.projects.length; i++) {
            woleBag.push({
              donID: "8",
              userName: newUserData.data.data?.name_a,
              userMob: newUserData.data.data?.mobile_no,
              userEmail: newUserData.data.data?.email,
              itemType: "2",
              amount: currentProfile.cart.projects[i]?.projectAmount,
              cart_serial: null,
              workPlaceID: currentProfile.cart.orphans[i]?.workPlaceID,
              prj_name: currentProfile.cart.projects[i]?.projectName,
              projectYear: currentProfile.cart.projects[i]?.projectYear,
              template_id: currentProfile.cart.projects[i]?.projectId,
              country_id: currentProfile.cart.projects[i]?.countryId,
            });
          }
          for (let i = 0; i < currentProfile.cart.sukuks.length; i++) {
            woleBag.push({
              donID: currentProfile.cart.sukuks[i].sukId,
              userName: newUserData.data.data?.name_a,
              userMob: newUserData.data.data?.mobile_no,
              userEmail: newUserData.data.data?.email,
              itemType: 0,
              amount: currentProfile.cart.sukuks[i]?.amount,
              recuring: currentProfile.cart.sukuks[i]?.donationFreq,
              cart_serial: null,
            });
          }
          //----------Move Favorites-----------
          for (let i = 0; i < currentProfile.liked.donations.length; i++) {
            wholeFav.push({
              email: newUserData.data.data?.email,
              dontypeID: currentProfile.liked.donations[i]?.donationId,
              projectID: "",
              orptypeID: "",
              projectYear: "",
              countryID: "",
            });
          }
          for (let i = 0; i < currentProfile.liked.orphans?.length; i++) {
            wholeFav.push({
              email: newUserData.data.data?.email,
              dontypeID: "",
              projectID: "",
              orptypeID: currentProfile.liked.orphans[i]?.orphanId,
              projectYear: "",
              countryID: currentProfile.liked.orphans[i]?.orphanCountryId,
            });
          }
          for (let i = 0; i < currentProfile.liked.projects.length; i++) {
            wholeFav.push({
              email: newUserData.data.data?.email,
              dontypeID: "",
              projectID: currentProfile.liked.projects[i]?.projectId,
              orptypeID: "",
              projectYear: currentProfile.liked.projects[i]?.projectYear,
              countryID: "",
            });
          }
          for (let i = 0; i < currentProfile.liked.sukuks.length; i++) {
            wholeFav.push({
              email: newUserData.data.data?.email,
              dontypeID: currentProfile.liked.sukuks[i]?.sukId,
              projectID: "",
              orptypeID: "",
              projectYear: "",
              countryID: "",
            });
          }
          await dispatch(
            addToBag({
              cart: woleBag.slice(0),
              language: lng === "arab" ? 1 : 2,
            })
          );
          await dispatch(
            addToFavorites(lng === "arab" ? 1 : 2, wholeFav, "array")
          );
        }
        localStorage.removeItem("profile");
        setLoadingModalShow(false);
        setDoneModal(true);
        dispatch({ type: AUTH, data: { Results: [newUserData.data.data] } });
        // window.open('/profile', '_self')
      } else {
        setLoadingModalShow(false);
        toastifyMessage(
          "Something went wrong...!",
          newUserData.data.details,
          "warning"
        );
      }
    } catch (error) {
      setLoadingModalShow(false);
      toastifyMessage("Something went wrong...!", undefined, "error");
    }
  };
export const addCard =
  (
    email,
    expiryMonth,
    expiryYear,
    number,
    cvc,
    setLoadingModalShow,
    setDoneModal
  ) =>
  async (dispatch) => {
    try {
      let token = await api.tokenizeCard(expiryMonth, expiryYear, number, cvc);
      if (token.data.token) {
        let res = await api.addCardApi(token.data.token, email);
        let oldUser = JSON.parse(localStorage.getItem("userLogin"));
        oldUser.Results[0].user_tokens = res.data.data.slice();
        localStorage.setItem("userLogin", JSON.stringify(oldUser));
        setLoadingModalShow(false);
        setDoneModal(true);
      } else {
        setLoadingModalShow(false);
        toastifyMessage("Something went wrong...!", undefined, "error");
      }
    } catch (error) {
      setLoadingModalShow(false);
      toastifyMessage("Something went wrong...!", undefined, "error");
    }
  };

export const reSendOTP =
  (email, setLoadingModalShow, setContentOf) => async (dispatch) => {
    try {
      let newUserData = await api.reSendOTP(email);
      if (newUserData.data["status"] === true) {
        setLoadingModalShow(false);
        toastifyMessage("Sent!", undefined, "success");
      } else {
        setLoadingModalShow(false);
        toastifyMessage("Email does not exist", undefined, "warning");
      }
    } catch (error) {
      setLoadingModalShow(false);
      toastifyMessage("Something went wrong...!", undefined, "error");
    }
  };

export const askingForReset =
  (userData, setLoadingModalShow, setContentOf, setDisplay) =>
  async (dispatch) => {
    try {
      let newUserData = await api.resetPassReq(userData);
      if (
        newUserData.data["Results"].slice(9) === userData.email &&
        newUserData.data["Results"]
      ) {
        setLoadingModalShow(false);
        setDisplay(2);
      } else {
        setLoadingModalShow(false);
        toastifyMessage("Email does not exist", undefined, "warning");
      }
    } catch (error) {
      setLoadingModalShow(false);
      toastifyMessage("Something went wrong...!", undefined, "error");
    }
  };
export const setTheEsetOTP =
  (userData, setLoadingModalShow, setContentOf, setDisplay) =>
  async (dispatch) => {
    try {
      let newUserData = await api.resetPassOTP(userData);
      if (newUserData.data === "FAIL") {
        setLoadingModalShow(false);
        toastifyMessage("The Verification Code Is Wrong", undefined, "warning");
      } else {
        setLoadingModalShow(false);
        setDisplay(3);
      }
    } catch (error) {
      setLoadingModalShow(false);
      toastifyMessage("Something went wrong...!", undefined, "error");
    }
  };

export const updatePassword =
  (userData, setLoadingModalShow, setDoneModal) => async (dispatch) => {
    try {
      let newUserData = await api.updatePassword(userData);
      if (newUserData.data === "SUCCESS") {
        setLoadingModalShow(false);
        setDoneModal(true);
        // history.push("/");
      } else {
        setLoadingModalShow(false);
        toastifyMessage("Something went wrong...!", undefined, "error");
      }
    } catch (error) {
      setLoadingModalShow(false);
      toastifyMessage("Something went wrong...!", undefined, "error");
    }
  };

export const getingYourAcc =
  (userID, setLoadingModalShow, setAccount, setDisplay) => async (dispatch) => {
    try {
      let newUserData = await api.gettingTheAccount(userID);
      if (newUserData.data.result.status === true) {
        setLoadingModalShow(false);
        setAccount(newUserData.data.result);
        setDisplay(2);
      } else {
        setLoadingModalShow(false);
        toastifyMessage("User Not Found", undefined, "warning");
      }
    } catch (error) {
      toastifyMessage("Something went wrong...!", undefined, "error");
      setLoadingModalShow(false);
    }
  };

  export const getSPDonationSummary =
  (id, lang, device_type) => async (dispatch) => {
    dispatch({ type: DONATIONS_LOADING });
    try {
      const { data } = await account.getDonationSummary(id, lang, device_type);
      dispatch({ type: FETCH_ALL_DONATIONSSUMMARY, payload: data });
      dispatch({ type: DONATIONS_LOADING_FALSE });
    } catch (error) {
      //toastifyMessage("Something went wrong...!", undefined, "error");
      dispatch({ type: DONATIONS_LOADING_FALSE });
    }
  };
  export const getSPTransactionSummary =
  (id, lang) => async (dispatch) => {
    dispatch({ type: TRANSACTIONS_LOADING });
    try {
      const { data } = await account.getTransactionsSummary(id, lang);
      dispatch({ type: FETCH_ALL_TRANSACTIONSSUMMARY, payload: data.status? data.data : [] });
      dispatch({ type: TRANSACTIONS_LOADING_FALSE });
    } catch (error) {
      //toastifyMessage("Something went wrong...!", undefined, "error");
      dispatch({ type: TRANSACTIONS_LOADING_FALSE });
    }
  };
export const getSPFollowups = (id, lang, orphanId) => async (dispatch) => {
  dispatch({ type: LOAD_SPONSOR_FOLLOWUPS });
  try {
    const { data } = await account.getMyOrphonFollowups(id, lang, orphanId);
    dispatch({ type: FETCH_SPONSORED_FOLLOW_UPS, payload: data });
    dispatch({ type: SPONSOR_FOLLOWUPS_LOADED });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
    dispatch({ type: SPONSOR_FOLLOWUPS_LOADED });
  }
};

export const getSPNotifications = (email, lang) => async (dispatch) => {
  dispatch({ type: LOAD_NOTIFICATIONS });
  try {
    const { data } = await account.getMyNotifications(email, lang);
    dispatch({ type: FETCH_ALL_NOTIFICATIONS, payload: data });
    dispatch({ type: NOTIFICATIONS_LOADED });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
    dispatch({ type: NOTIFICATIONS_LOADED });
  }
};
export const readSPNotifications = (email, lang) => async (dispatch) => {
  try {
    await account.readMyNotifications(email);
    const { data } = await account.getMyNotifications(email, lang);
    dispatch({ type: FETCH_ALL_NOTIFICATIONS, payload: data });
  } catch {}
};
export const getSPOrphonsList = (id, lang) => async (dispatch) => {
  dispatch({ type: LOAD_SPONSOR });
  try {
    const { data } = await account.getMyOrphons(id, lang);
    dispatch({ type: FETCH_ALL_ORPHONS, payload: data["Orphans "] });
    // dispatch({ type: SPONSOR_LOADED });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
    dispatch({ type: SPONSOR_LOADED });
  }
};
export const getSPAllLatepayments = (id, language) => async (dispatch) => {
  dispatch({ type: ALL_ORPHON_LATE_PAYMENTS_LOADING });
  try {
    const { data } = await account.getAllLatePayments(id, language);
    dispatch({ type: ALL_ORPHONS_LATE_PAYMENTS, payload: data });
  } catch (error) {
    dispatch({ type: ALL_ORPHON_LATE_PAYMENTS_ERROR, payload: error });
    //toastifyMessage("Something went wrong...!", undefined, "error");
  }
};
export const getSPOrphAllLatepayments =
  (id, language, orphanId) => async (dispatch) => {
    dispatch({ type: ALL_ORPHON_LATE_PAYMENTS_LOADING });
    try {
      const { data } = await account.getLatePaymentsByOrp(
        id,
        language,
        orphanId
      );
      dispatch({ type: ALL_ORPHON_LATE_PAYMENTS, payload: data });
    } catch (error) {
      dispatch({ type: ALL_ORPHON_LATE_PAYMENTS_ERROR, payload: error });
      //toastifyMessage("Something went wrong...!", undefined, "error");
    }
  };

export const signUp =
  (formData, setLoadingModalShow, history) => async (dispatch) => {
    try {
      const checkingResult = await api.emailChecker(formData.email);
      if (checkingResult.data["User Name"] === "Not Found") {
        var newUserData = await api.signUp(formData);
        if (newUserData) {
          setLoadingModalShow(false);
          if (newUserData.data[0].main_acc !== formData.email) {
            toastifyMessage(
              "The Email/Mobile Number/EID is already linked",
              undefined,
              "warning"
            );
          } else {
            localStorage.removeItem("visitorDetails");
            history.push(`/auth/needToOTP`, {
              email: newUserData.data[0].main_acc,
              from: "signUp",
            });
          }
        } else {
          setLoadingModalShow(false);
          toastifyMessage("Something went wrong...!", undefined, "error");
        }
      } else {
        setLoadingModalShow(false);
        toastifyMessage(
          "This E-mail Exists, Please Log In",
          undefined,
          "warning"
        );
      }
    } catch (error) {
      //toastifyMessage("Something went wrong...!", undefined, "error");
      setLoadingModalShow(false);
    }
  };
export const changeAccount = (sponserID) => (dispatch) => {
  dispatch({ type: SET_SPONSER_ACCOUNT, payload: sponserID });
};
export const clearAccount = (dispatch) => {
  dispatch({ type: CLEAR_SPONSER_ACCOUNT, payload: "" });
};
