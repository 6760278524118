import React, { useEffect, useRef, useState } from 'react';
import './header.css';
import ENG from '../../../assets/flag.png';
import UAE from '../../../assets/united-arab-emirates.svg';
import ERC from '../../../assets/logo_desktop_whiteV2.svg';
import favorites from '../../../assets/qalb.png';
import bagIcon from '../../../assets/bag.png';

import profileIcon from '../../../assets/profile.png';
import notficationIcon from '../../../assets/notigicato.png';

import ERCMob from '../../../assets/rclogo-lightgray.png';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HeaderSidebar from './sideMenuNavi/SideMenuNavi';
import { useSelector, useDispatch } from 'react-redux';
import { readSPNotifications } from '../../../actions/user';
import Loading from '../../ui/loading';
import SummerForm from '../../../assets/نموذج طلب التدريب الصيفي.pdf';
import SupplierInvoiceGuide from '../../../assets/supplier_invoice_guide.pdf';
import { getCommunityAww } from '../../../api';
import { use } from 'i18next';

const Header = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  let userLogin = JSON.parse(localStorage.getItem('userLogin'));
  const [displayMenu1, setDisplayMenu1] = useState(false);
  const [displayMenu2, setDisplayMenu2] = useState(false);
  const [displayMenu3, setDisplayMenu3] = useState(false);

  const [openNorficationModal, setOpenNorficationModal] = useState(false);

  let currentProfile = JSON.parse(localStorage.getItem('profile'));
  const fetchLoaclly = useSelector((state) => state.fetchLocallyState);
  useEffect(() => {
    currentProfile = JSON.parse(localStorage.getItem('profile'));
  }, [fetchLoaclly]);

  const Inner = (str) => {
    if (str === 'eng') {
      localStorage.setItem('language', JSON.stringify({ lang: 'en' }));
      //props.setChangeOfLanguage(str)
      window.location.reload();
    } else {
      localStorage.setItem('language', JSON.stringify({ lang: 'arab' }));
      //props.setChangeOfLanguage(str)
      window.location.reload();
    }
  };
  const cartItemCounter = useSelector((state) => state.bag.bag);
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const location = useLocation();
  const [communityLinks, setCommunityLinks] = useState([]);
  const getAwwrenessLinks = async () => {
    try {
      const { data } = await getCommunityAww(lng === 'arab' ? 1 : 2);
      if (data?.status) {
        setCommunityLinks(data?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    // if (userLogin) {
      getAwwrenessLinks();
    // }
  }, [lng]);
  useEffect(() => {
    if (!userLogin) {
      let length = 0;
      for (const property in currentProfile?.cart) {
        length = currentProfile?.cart[property].length + length;
      }
      if (length != null) {
        setCartItemsCount(length);
      }
    } else {
      if (cartItemCounter?.total != null) {
        setCartItemsCount(cartItemCounter?.total);
      }
    }
  }, [cartItemCounter, location, fetchLoaclly]);
  const userInfo = useSelector((state) => {
    return state.auth.authData;
  });
  const allNotifications = useSelector((state) => {
    return state.auth.notifications;
  });
  const loadNotifications = useSelector((state) => {
    return state.auth.notificationLoading;
  });
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(10, 0);
  }, [location]);
  const switchOpen = () => {
    if (userLogin) {
      history.push('/profile');
    } else {
      history.push('/auth');
    }
  };
  const [notfications, setNotifications] = useState([]);
  useEffect(() => {
    setNotifications(allNotifications);
  }, [allNotifications]);
  const dispatch = useDispatch();
  const handleNotifications = () => {
    if (openNorficationModal) {
      setOpenNorficationModal(false);
    } else {
      if (notfications?.filter((item) => !item.is_read).length > 0) {
        dispatch(
          readSPNotifications(
            userLogin.Results[0].email,
            lng === 'arab' ? 1 : 2
          )
        );
      }
      setOpenNorficationModal(true);
    }
  };
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenNorficationModal(false);
          // if (notfications?.filter(item => !item.is_read).length > 0) {
          //   dispatch(readSPNotifications(userLogin.Results[0].email, lng === "arab" ? 1 : 2))
          // }
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, notfications]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const openFromNav = (str) => {
    switch (str) {
      case 'home':
        history.push(`/`);
        break;
      case 'donations':
        history.push(`/donations`);
        break;
      case 'projects':
        history.push(`/projects/all`);
        break;
      case 'categories':
        history.push(`/categories`);
        break;
      case 'mybag':
        history.push(`/mybag`);
        break;
      case 'contact_us':
        history.push(`/contact-us`);
        break;
      case 'orphans':
        history.push(`/beasponser/all`);
        break;
      case 'course':
        history.push(`/course`);
        break;
      case 'events-attendance':
        history.push(`/events-attendance`);
        break;
      case 'events-join':
        history.push(`/events-join`);
        break;
      case 'donate-idea':
        history.push(`/donate-idea`);
        break;
      case 'donate-estate':
        history.push(`/donate-estate`);
        break;
        case 'landing-page':
        history.push(`/landingpages`);
        break;
      default:
        history.push(`/`);
        break;
    }
  };
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  const pathnameStyle = {
    color: '#ee4236',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 3px 2px #ee4236'
  };
  useEffect(() => {
    if (width > 900) {
      if (location.pathname === '/') {
        document.getElementById('home-nav').style.color = pathnameStyle.color;
        document.getElementById('home-nav').style.backgroundColor =
          pathnameStyle.backgroundColor;
        document.getElementById('home-nav').style.boxShadow =
          pathnameStyle.boxShadow;
      } else {
        document.getElementById('home-nav').style = 'unset';
      }
      if (
        location.pathname === '/categories' ||
        location.pathname === '/donations' ||
        location.pathname === '/projects/all' ||
        location.pathname === '/beasponser/all'
      ) {
        document.getElementById('categories-nav').style.color =
          pathnameStyle.color;
        document.getElementById('categories-nav').style.backgroundColor =
          pathnameStyle.backgroundColor;
        document.getElementById('categories-nav').style.boxShadow =
          pathnameStyle.boxShadow;
      } else {
        document.getElementById('categories-nav').style = 'unset';
      }
      if (
        location.pathname === '/grace-conservation' ||
        location.pathname === '/volunteer' ||
        location.pathname === '/localAid' ||
        location.pathname === '/volunteer/vln-registration'
      ) {
        document.getElementById('services-nav').style.color =
          pathnameStyle.color;
        document.getElementById('services-nav').style.backgroundColor =
          pathnameStyle.backgroundColor;
        document.getElementById('services-nav').style.boxShadow =
          pathnameStyle.boxShadow;
      } else {
        document.getElementById('services-nav').style = 'unset';
      }
      if (
        location.pathname === '/contact-us' ||
        location.pathname === '/our-locations' ||
        location.pathname === '/media'
      ) {
        document.getElementById('contact-us').style.color = pathnameStyle.color;
        document.getElementById('contact-us').style.backgroundColor =
          pathnameStyle.backgroundColor;
        document.getElementById('contact-us').style.boxShadow =
          pathnameStyle.boxShadow;
      } else {
        document.getElementById('contact-us').style = 'unset';
      }

      if (
        (location.pathname === '/auth') |
        (location.pathname === '/auth/needToOTP') |
        (location.pathname === '/auth/reset-password') |
        (location.pathname === '/auth/get-account') |
        (location.pathname === '/sponsership/followups') |
        (location.pathname === '/volunteer/certificate-pdf')
      ) {
        document.getElementById('header').style.display = 'none';
      } else {
        document.getElementById('header').style.display = 'flex';
      }
    }
  }, [location.pathname, history, width]);
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  }

  if (width > 900) {
    return (
      <header id="header">
        <div id="select_lang_area">
          <div className="selectLanguageWraper">
            <button
              style={{
                marginTop:
                  navigator.userAgent.indexOf('Safari') > -1 &&
                  navigator.userAgent.indexOf('Chrome') <= -1
                    ? '-20px'
                    : '-35px'
              }}
              className="dropDown selectLanguageSection"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {lng === 'arab' ? (
                <React.Fragment>
                  <img src={UAE} alt="Eng_Logo" className="lang_logo inDrop" />{' '}
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: 'bold',
                      fontFamily: "'arab_font'"
                    }}
                  >
                    {' '}
                    ع
                  </span>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <img
                    src={ENG}
                    alt="Eng_Logo"
                    className="lang_logo inDrop payment_numbers"
                  />{' '}
                  <span style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    ENG
                  </span>
                </React.Fragment>
              )}
            </button>
            <div
              id="dropDownMenuTest"
              className="dropdown-menu"
              aria-labelledby="Lang"
            >
              {lng === 'arab' ? (
                <button
                  style={{ cursor: 'pointer' }}
                  onClick={() => Inner('eng')}
                  className="dropdown-item"
                  id="eng"
                >
                  <img src={ENG} alt="Eng_Logo" className="lang_logo" />
                  <label
                    className="payment_numbers"
                    style={{
                      width: '34px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      marginTop: '6px'
                    }}
                  >
                    ENG
                  </label>
                </button>
              ) : (
                <button
                  onClick={() => Inner('arabic')}
                  style={{ justifyContent: 'space-between', cursor: 'pointer' }}
                  className="dropdown-item"
                  id="ar"
                >
                  <img src={UAE} alt="Ar_Logo" className="lang_logo" />
                  <label
                    className="force_arabic_font"
                    style={{
                      width: '34px',
                      textAlign: 'center',
                      fontFamily: "'arab_font'",
                      cursor: 'pointer'
                    }}
                  >
                    {' '}
                    ع
                  </label>
                </button>
              )}
            </div>
          </div>
          <div className="header_btns">
            {userInfo ? (
              <div ref={wrapperRef}>
                <button
                  type="button"
                  className="header_icons position-relative"
                  onClick={handleNotifications}
                >
                  <img
                    style={{ height: '30px', width: '30px' }}
                    src={notficationIcon}
                    alt="favorites_icon"
                  />
                  {/* {<i className="fas fa-bell"></i>} */}
                  {allNotifications?.filter(function (item) {
                    return !item.is_read;
                  }).length > 0 ? (
                    <span
                      style={{ fontFamily: 'english_font' }}
                      className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                    >
                      {
                        notfications?.filter(function (item) {
                          return !item.is_read;
                        }).length
                      }
                    </span>
                  ) : (
                    <span
                      style={{ fontFamily: 'english_font' }}
                      className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                    >
                      0
                    </span>
                  )}
                </button>
                {openNorficationModal ? (
                  <div
                    className="dropdown-menu notfications_menu notfications_menu_desktop dropdown-menu-right"
                    style={{ padding: '0px', marginTop: '-4px' }}
                  >
                    <h2 style={{ padding: '20px', textAlign: 'center' }}>
                      {t('Notifications')}
                    </h2>
                    <br />
                    {loadNotifications ? (
                      <Loading />
                    ) : allNotifications.length > 0 ? (
                      allNotifications.map((not, i) => {
                        return (
                          <div
                            className="notfication"
                            key={i}
                            style={{
                              padding: '5px 20px 5px 20px',
                              borderBottom: '1px solid gray',
                              cursor: 'pointer',
                              position: 'relative',
                              direction: lng === 'arab' ? 'rtl' : 'ltr'
                            }}
                          >
                            <label
                              className="payment_numbers"
                              style={{
                                fontWeight: 'bold',
                                textAlign: lng === 'arab' ? 'right' : 'left'
                              }}
                            >
                              {not.timestamp}
                            </label>
                            <p
                              style={
                                !not.is_read
                                  ? {
                                      fontWeight: 'bold',
                                      textAlign:
                                        lng === 'arab' ? 'right' : 'left'
                                    }
                                  : {
                                      textAlign:
                                        lng === 'arab' ? 'right' : 'left'
                                    }
                              }
                            >
                              {not.message}
                            </p>
                            <div
                              style={{
                                display: !not.is_read ? 'flex' : 'none',
                                position: 'absolute',
                                left: '0px',
                                top: '0px',
                                bottom: '0px',
                                backgroundColor: '#ee4236',
                                width: '10px'
                              }}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div>
                        <p style={{ textAlign: 'center' }}>
                          {t('No notifications')}
                        </p>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            ) : null}

            <button
              style={{ textDecoration: 'none' }}
              onClick={() => history.push('/favorites')}
              className="btn btn-block btn-light selectLanguageSection header_icons"
            >
              <img
                style={{ height: '30px', width: '30px' }}
                src={favorites}
                alt="favorites_icon"
              />
              {/*<i className="fas fa-heart " style={{ fontSize: '25px', color: '#ee4236' }}></i> */}
            </button>

            <button
              style={{ position: 'relative' }}
              onClick={() => history.push('/mybag')}
              type="button"
              className="selectLanguageSection header_icons"
            >
              <img
                style={{ height: '30px', width: '30px' }}
                src={bagIcon}
                alt="favorites_icon"
              />
              {/* {<i className="fas fa-shopping-bag"></i>} */}
              <span
                style={{ color: 'black', fontFamily: 'english_font' }}
                className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light"
              >
                {cartItemsCount}
              </span>
            </button>

            <button
              style={{ textDecoration: 'none' }}
              onClick={() => history.push(userLogin ? '/profile' : '/auth')}
              className="btn btn-block btn-light selectLanguageSection header_icons"
            >
              <img
                style={{ height: '30px', width: '30px' }}
                src={profileIcon}
                alt="favorites_icon"
              />
              {/* {<i className="fas fa-user"></i>} */}
            </button>
          </div>
        </div>

        <div id="down_header">
          <div id="main_header">
            <div
              onClick={() => openFromNav('home')}
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: '1',
                cursor: 'pointer'
              }}
            >
              <img src={ERC} alt="ERC_logo" className="ERC_logo" />
            </div>
          </div>
          <nav
            className="nav_header_main"
            id={lng === 'arab' ? 'nav_header_main-ar' : 'nav_header_main'}
          >
            <ul
              style={
                lng === 'arab'
                  ? { flexDirection: 'row-reverse' }
                  : { flexDirection: 'row' }
              }
            >
              <li>
                <div
                  style={{ width: '75px', color: '#fff' }}
                  onClick={() => openFromNav('home')}
                  className="btnForNav"
                  id="home-nav"
                >
                  {t('Home')}
                </div>
              </li>
              <li
                className="dropdown show"
                id="categories"
                style={lng === 'arab' ? { direction: 'rtl' } : null}
              >
                <div className="dropdown show">
                  <a
                    style={{ color: '#fff' }}
                    className="btnForNav dropdown"
                    id="categories-nav"
                    href="#"
                    role="button"
                    // data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {t('Categories')}
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li>
                      <button
                        onClick={() => openFromNav('donations')}
                        className="dropdown-item"
                      >
                        {t('Donations')}
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => openFromNav('orphans')}
                        className="dropdown-item"
                      >
                        {t('Become a Sponsor')}
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => openFromNav('projects')}
                        className="dropdown-item"
                      >
                        {t('Charity projects')}
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => openFromNav('categories')}
                        className="dropdown-item"
                      >
                        {t('All categories')}
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => openFromNav('course')}
                        className="dropdown-item"
                      >
                        {t('Course')}
                      </button>
                    </li>
                    {/* <li>
                      <button
                        onClick={() => openFromNav('events-attendance')}
                        className="dropdown-item"
                      >
                        {t('Events Attendance')}
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => openFromNav('events-join')}
                        className="dropdown-item"
                      >
                        {t('Events Join')}
                      </button>
                    </li> */}
                    <li>
                      <button
                        onClick={() => openFromNav('donate-idea')}
                        className="dropdown-item"
                      >
                        {t('Donate Idea')}
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => openFromNav('donate-estate')}
                        className="dropdown-item"
                      >
                        {t('Donate with Real Estate')}
                      </button>
                    </li>
                    {communityLinks && communityLinks?.length > 0 && (
                      <li>
                        <button
                          onMouseOver={() => setDisplayMenu1(true)}
                          onClick={() => setDisplayMenu1(!displayMenu1)}
                          className="dropdown-item buttonHeader1"
                        >
                          {t('Community Awareness links')}
                        </button>
                        {communityLinks &&
                          communityLinks.map((link, i) => (
                            <div
                              style={{
                                display: displayMenu1 ? 'flex' : 'none',
                                padding:
                                  lng === 'arab'
                                    ? '3px 10px 3px 0px'
                                    : '3px 0px 3px 10px'
                              }}
                              className="subHoveringMenu1"
                            >
                              <button
                                style={
                                  lng === 'arab'
                                    ? { borderRight: '3px solid #ee4236' }
                                    : { borderLeft: '3px solid #ee4236' }
                                }
                                onClick={() => window.open(link.att_location)}
                                className="dropdown-item"
                              >
                                {t(link.att_name || 'link 1')}
                              </button>
                            </div>
                          ))}
                      </li>
                    )}
                  </ul>
                </div>
              </li>
              <li
                className="dropdown show"
                id="services"
                style={lng === 'arab' ? { direction: 'rtl' } : null}
              >
                <div className="dropdown show ">
                  <a
                    style={{ color: '#fff', backgroundColor: '#30302f' }}
                    className="btnForNav dropdown"
                    id="services-nav"
                    href="#"
                    role="button"
                    // data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {t('Services')}
                  </a>
                  <ul
                    onMouseLeave={() => {
                      setDisplayMenu2(false);
                      setDisplayMenu3(false);
                      setDisplayMenu1(false);
                    }}
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li>
                      <a
                        href={SummerForm}
                        download={
                          lng === 'arab'
                            ? 'نموذج طلب التدريب الصيفي'
                            : 'Summer Course Registration Form'
                        }
                        target="_blank"
                        className="dropdown-item form_download"
                      >
                        {t('Summer Course Registration Form')}
                      </a>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          (window.location.href =
                            'https://www.emiratesrc.ae/TrainingCourses/')
                        }
                        target="_blank"
                        className="dropdown-item"
                      >
                        {t('Training Courses ')}
                      </button>
                    </li>
                    <li>
                      <button
                        onMouseOver={() => setDisplayMenu3(true)}
                        onClick={() => setDisplayMenu3(!displayMenu3)}
                        className="dropdown-item buttonHeader1"
                      >
                        {t('Volunteering Service')}
                      </button>
                      <div
                        style={{
                          display: displayMenu3 ? 'flex' : 'none',
                          padding:
                            lng === 'arab'
                              ? '3px 10px 3px 0px'
                              : '3px 0px 3px 10px'
                        }}
                        className="subHoveringMenu1"
                      >
                        <button
                          style={
                            lng === 'arab'
                              ? { borderRight: '3px solid #ee4236' }
                              : { borderLeft: '3px solid #ee4236' }
                          }
                          onClick={() =>
                            history.push('/volunteer/vln-registration')
                          }
                          className="dropdown-item"
                        >
                          {t('Login')}
                        </button>
                        <button
                          style={
                            lng === 'arab'
                              ? { borderRight: '3px solid #ee4236' }
                              : { borderLeft: '3px solid #ee4236' }
                          }
                          onClick={() =>
                            lng === 'arab'
                              ? window.open(
                                  'https://forms.gle/yPmUBLsnLZHjcrgF9'
                                )
                              : window.open(
                                  'https://forms.gle/N67PE8ZU1HVbykMQ6'
                                )
                          }
                          className="dropdown-item"
                        >
                          {t('Volunteer Satisfaction and Happiness Form')}
                        </button>
                      </div>
                    </li>

                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() =>
                          history.push(
                            userLogin?.Results[0]?.person_id_flag === '2'
                              ? '/localAid'
                              : userLogin
                              ? '/profile'
                              : '/auth'
                          )
                        }
                      >
                        {t('Local Aid')}
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          history.push(userLogin ? '/profile' : '/auth')
                        }
                        className="dropdown-item"
                      >
                        {t('Beneficent Portal')}
                      </button>
                    </li>
                    <li>
                      <button
                        onMouseOver={() => setDisplayMenu1(true)}
                        onClick={() => setDisplayMenu1(!displayMenu1)}
                        className="dropdown-item buttonHeader1"
                      >
                        {t('Supplier Services')}
                      </button>
                      <div
                        style={{
                          display: displayMenu1 ? 'flex' : 'none',
                          padding:
                            lng === 'arab'
                              ? '3px 10px 3px 0px'
                              : '3px 0px 3px 10px'
                        }}
                        className="subHoveringMenu1"
                      >
                        <button
                          style={
                            lng === 'arab'
                              ? { borderRight: '3px solid #ee4236' }
                              : { borderLeft: '3px solid #ee4236' }
                          }
                          onClick={() =>
                            window.open(
                              'https://erp.rcuae.ae/OA_HTML/AppsLocalLogin.jsp'
                            )
                          }
                          className="dropdown-item"
                        >
                          {t('Supplier login')}
                        </button>
                        <button
                          style={
                            lng === 'arab'
                              ? { borderRight: '3px solid #ee4236' }
                              : { borderLeft: '3px solid #ee4236' }
                          }
                          onClick={() =>
                            window.open(
                              lng === 'arab'
                                ? 'https://erp.rcuae.ae:443/OA_HTML/jsp/pos/suppreg/SupplierRegister.jsp?ouid=5055E79729E7A9FD&lang=AR'
                                : 'https://erp.rcuae.ae:443/OA_HTML/jsp/pos/suppreg/SupplierRegister.jsp?ouid=5055E79729E7A9FD&lang=US'
                            )
                          }
                          className="dropdown-item"
                        >
                          {t('Register a new supplier')}
                        </button>
                        <a
                          href={SupplierInvoiceGuide}
                          download={
                            lng === 'arab'
                              ? 'ارشادات خدمة التوريد'
                              : 'Supplier Invoice Guide'
                          }
                          target="_blank"
                          className="dropdown-item form_download"
                          style={
                            lng === 'arab'
                              ? { borderRight: '3px solid #ee4236' }
                              : { borderLeft: '3px solid #ee4236' }
                          }
                        >
                          {t('Supplier Invoice Guide')}
                        </a>
                      </div>
                    </li>
                    <li>
                      <button
                        onMouseOver={() => setDisplayMenu2(true)}
                        onClick={() => setDisplayMenu2(!displayMenu2)}
                        className="dropdown-item buttonHeader1"
                      >
                        {t('Internal Services')}
                      </button>
                      <div
                        style={{
                          display: displayMenu2 ? 'flex' : 'none',
                          padding:
                            lng === 'arab'
                              ? '3px 10px 3px 0px'
                              : '3px 0px 3px 10px'
                        }}
                        className="subHoveringMenu2"
                      >
                        <button
                          style={
                            lng === 'arab'
                              ? { borderRight: '3px solid #ee4236' }
                              : { borderLeft: '3px solid #ee4236' }
                          }
                          onClick={() =>
                            window.open(
                              'http://portal.rcuae.ae/local_aid/user_login_log.aspx'
                            )
                          }
                          className="dropdown-item"
                        >
                          {t('Purchasing Committees Accreditation Portal')}
                        </button>
                        <button
                          style={
                            lng === 'arab'
                              ? { borderRight: '3px solid #ee4236' }
                              : { borderLeft: '3px solid #ee4236' }
                          }
                          onClick={() =>
                            window.open(
                              'http://portal.rcuae.ae/local_aid/user_login.aspx'
                            )
                          }
                          className="dropdown-item"
                        >
                          {t('Portal of aid and accreditation of committees')}
                        </button>
                        <button
                          style={
                            lng === 'arab'
                              ? { borderRight: '3px solid #ee4236' }
                              : { borderLeft: '3px solid #ee4236' }
                          }
                          onClick={() =>
                            window.open('https://portal.rcuae.ae/orphans_sys/')
                          }
                          className="dropdown-item"
                        >
                          {t('Sponsorships and Orphans Affairs Portal')}
                        </button>
                        <button
                          style={
                            lng === 'arab'
                              ? { borderRight: '3px solid #ee4236' }
                              : { borderLeft: '3px solid #ee4236' }
                          }
                          onClick={() =>
                            window.open(
                              'https://apex.rcuae.ae/ords/r/erc_apps/erc-hub/login'
                            )
                          }
                          className="dropdown-item"
                        >
                          {t('External project management system')}
                        </button>
                      </div>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          (window.location.href =
                            getMobileOperatingSystem() === 'iOS'
                              ? 'https://apps.apple.com/ae/app/neama/id6443719244'
                              : 'https://play.google.com/store/apps/details?id=ae.rcuae.neama')
                        }
                        className="dropdown-item"
                      >
                        {t('Grace Conservation')}
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          (window.location.href =
                            'https://www.alghadeeruaecrafts.ae/')
                        }
                        target="_blank"
                        className="dropdown-item"
                      >
                        {t('Al Ghadeer UAE Crafts')}
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          (window.location.href =
                            'https://www.atayaprojects.ae/')
                        }
                        target="_blank"
                        className="dropdown-item"
                      >
                        {t('Ataya')}
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => openFromNav('landing-page')}
                        className="dropdown-item"
                      >
                        {t('Landing Page')}
                      </button>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className="dropdown show"
                id="contact"
                style={lng === 'arab' ? { direction: 'rtl' } : null}
              >
                <div className="dropdown show">
                  <a
                    style={{ color: '#fff' }}
                    className="btnForNav dropdown"
                    id="contact-us"
                    href="#"
                    role="button"
                    // data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {t('Contact')}
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => history.push('/media')}
                      >
                        {t('Media')}
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => history.push('/contact-us')}
                      >
                        {t('Contact Us')}
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => history.push('/our-locations')}
                        className="dropdown-item"
                      >
                        {t('Our Locations')}
                      </button>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
  } else if (width <= 900 && location.pathname === '/') {
    return (
      <header
        id="header"
        style={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          direction: lng === 'arab' ? 'rtl' : 'ltr'
        }}
      >
        <div id="select_lang_area">
          <div className="header_btns">
            {userInfo ? (
              <React.Fragment>
                <div
                  style={{
                    position: 'absolute',
                    borderRadius: '50%',
                    height: '30px',
                    width: '30px',
                    backgroundColor: '#d3d3d361',
                    filter: 'blur(8px)'
                  }}
                ></div>
                <div ref={wrapperRef}>
                  <button
                    type="button"
                    className="btn btn-block btn-light header_icons position-relative"
                    id="dropdownMenuButton2"
                    onClick={handleNotifications}
                    style={{ marginTop: '5px' }}
                  >
                    <i
                      style={{ textShadow: '1px 0px 8px gray', color: '#fff' }}
                      className="fas fa-bell"
                    ></i>
                    <span
                      style={{ fontFamily: 'english_font' }}
                      className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                    >
                      {
                        notfications?.filter(function (item) {
                          return !item.is_read;
                        }).length
                      }
                    </span>
                  </button>
                  {openNorficationModal ? (
                    <div
                      className="dropdown-menu notfications_menu notfications_menu_mobile dropdown-menu-right"
                      style={{
                        padding: '0px',
                        left: lng === 'arab' ? '10px' : 'auto',
                        right: lng === 'arab' ? 'auto' : '0',
                        marginTop: '0px'
                      }}
                    >
                      <h2 style={{ padding: '20px', textAlign: 'center' }}>
                        {t('Notifications')}
                      </h2>
                      <br />
                      {loadNotifications ? (
                        <Loading />
                      ) : allNotifications.length > 0 ? (
                        allNotifications.map((not, i) => {
                          return (
                            <div
                              className="notfication"
                              key={i}
                              style={{
                                padding: '5px 20px 5px 20px',
                                borderBottom: '1px solid gray',
                                cursor: 'pointer',
                                position: 'relative',
                                direction: lng === 'arab' ? 'rtl' : 'ltr'
                              }}
                            >
                              <label
                                className="payment_numbers"
                                style={{
                                  fontWeight: 'bold',
                                  textAlign: lng === 'arab' ? 'right' : 'left'
                                }}
                              >
                                {not.timestamp}
                              </label>
                              <p
                                style={
                                  !not.is_read
                                    ? {
                                        fontWeight: 'bold',
                                        textAlign:
                                          lng === 'arab' ? 'right' : 'left'
                                      }
                                    : {
                                        textAlign:
                                          lng === 'arab' ? 'right' : 'left'
                                      }
                                }
                              >
                                {not.message}
                              </p>
                              <div
                                style={{
                                  display: !not.is_read ? 'flex' : 'none',
                                  position: 'absolute',
                                  left: '0px',
                                  top: '0px',
                                  bottom: '0px',
                                  backgroundColor: '#ee4236',
                                  width: '10px'
                                }}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <div>
                          <p style={{ textAlign: 'center' }}>
                            {t('No notifications')}
                          </p>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
        <div
          id="down_header"
          style={{ flexDirection: lng === 'arab' ? 'row-reverse' : 'row' }}
        >
          <div
            style={{
              position: 'absolute',
              borderRadius: '50%',
              height: '25px',
              width: '30px',
              backgroundColor: 'rgb(0 0 0 / 22%)',
              filter: 'blur(10px)',
              left: lng === 'arab' ? 'auto' : '25px',
              right: lng === 'arab' ? '15px' : 'auto',
              top: '12px',
              boxShadow: '#0000009c 0px 0px 0px 1px'
            }}
          ></div>
          {userLogin ? (
            <div
              style={{
                position: 'absolute',
                left: lng === 'arab' ? 'auto' : '65px',
                right: lng === 'arab' ? '55px' : 'auto',
                top: '12px',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                direction: lng === 'arab' ? 'rtl' : 'ltr'
              }}
            >
              <img
                src={ERC}
                style={{ height: '35px', width: '27px' }}
                alt="ERC_logo"
              />
              <label
                style={{ color: 'white', textShadow: '#000000d6 1px 0px 8px' }}
              >
                <span
                  style={{
                    color: '#ee4236',
                    textShadow: 'rgb(238 66 54 / 71%) 1px 0px 16px'
                  }}
                >
                  {t('Hello,')}
                </span>{' '}
                {userLogin?.Results[0]?.name_a?.split(' ').shift()}
              </label>
            </div>
          ) : null}
          <HeaderSidebar setChangeOfLanguage={props.setChangeOfLanguage} />
        </div>
      </header>
    );
  } else {
    return <span></span>;
  }
};
export default Header;
