import * as api from "../api";
import { handleRegularPayment } from "./handleRegularPayment";
import { toastifyMessage } from "../handlers/toastifyMessage";

export const startApplePay = (body, dispatch, history, recieptFor) => {
  if (body.cart.length > 1) {
    let itemsAboveFive = body.cart.filter((item) => item.amount >= 5);
    if (itemsAboveFive.length < body.cart.length) {
      toastifyMessage("Can not pay less than 5 AED", undefined, "warning");
      return;
    }
  }
  if (body.amount < 5) {
    toastifyMessage("Can not pay less than 5 AED", undefined, "warning");
    return;
  }
  if (!window.ApplePaySession) {
    toastifyMessage("You Don't Have Apple Pay", undefined, "warning");
    return;
  }
  var merchantIdentifier = process.env.REACT_APP_APPLE_MERCHANT_ID;
  var promise =
    window.ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);
  promise.then(function (canMakePayments) {
    if (!canMakePayments) {
      toastifyMessage("You Don't Have Apple Pay", undefined, "warning");
    }
  });
  const request = {
    countryCode: "AE",
    currencyCode: "AED",
    merchantCapabilities: ["supports3DS"],
    supportedNetworks: ["visa", "masterCard", "amex", "discover"],
    total: {
      label: "Emirates Red Crescent",
      type: "final",
      amount: body.amount,
    },
  };
  const session = new window.ApplePaySession(3, request);
  session.onvalidatemerchant = (event) => {
    var location = new URL(window.location.href);
    api
      .validateApplePay(event.validationURL, location.hostname)
      .then((response) => session.completeMerchantValidation(response));
  };
  session.onpaymentauthorized = async (event) => {
    let masterToken = event.payment.token.paymentData;
    let masterTokenString = JSON.stringify(masterToken);

    var backend_body = {
      bag: body.cart,
      tokn: masterTokenString,
      payment_type: "APPLE",
    };
    let result = {};
    try {
      var res = await handleRegularPayment(
        backend_body,
        dispatch,
        null,
        recieptFor ? recieptFor : null
      );
      if (res) {
        if (res.status) {
          result = {
            status: window.ApplePaySession.STATUS_SUCCESS,
          };
          session.completePayment(result);
          history.push(`/receipt/${res.mco}`);
        } else {
          result = {
            status: window.ApplePaySession.STATUS_FAILURE,
          };
          session.completePayment(result);
        }
      } else {
        result = {
          status: window.ApplePaySession.STATUS_FAILURE,
        };
        session.completePayment(result);
      }
    } catch {
      result = {
        status: window.ApplePaySession.STATUS_FAILURE,
      };
      session.completePayment(result);
    }
  };
  // session.oncancel = event => {
  //     toastifyMessage("Payment has been cancelled", undefined, "warning");
  // };
  session.begin();
};
