import React, { useState, useEffect } from 'react'
import './FooterForMob.css'
import HomeRed from '../../../../assets/HomeRed.png'
import ProfileRed from '../../../../assets/ProfileRed.png'
import DonationRed from '../../../../assets/DonationRed.png'
import BagRed from '../../../../assets/BagRed.png'
import CategoryRed from '../../../../assets/CategoryRed.png'
import { useLocation, useHistory } from 'react-router-dom'
import ball from '../../../../assets/medicine-ball.png'
import { useSelector, useDispatch } from "react-redux";


const FooterForMob = () => {
    let userLogin = JSON.parse(localStorage.getItem('userLogin'))
    const [home, setHome] = useState(false)
    const [category, setCategory] = useState(false)
    const [donations, setDonations] = useState(false)
    const [profile, setProfile] = useState(false)
    const [bag, setBag] = useState(false)
    const location = useLocation();
    const history = useHistory()
    let currentProfile = JSON.parse(localStorage.getItem('profile'));
    const fetchLoaclly = useSelector(state => state.fetchLocallyState)
    useEffect(() => {
      currentProfile = JSON.parse(localStorage.getItem('profile'));
    }, [fetchLoaclly])

    const [cartItemsCount, setCartItemsCount]= useState(0)

    const cartItemCounter = useSelector((state) => state.bag.bag);

      useEffect(()=> {
        if (!userLogin) {
          let length = 0
          for (const property in currentProfile?.cart) {
              length = currentProfile?.cart[property].length + length
          }
          if (length != null) {
            setCartItemsCount(length)
          }
        } else {
            if (cartItemCounter?.total != null) {
               setCartItemsCount(cartItemCounter?.total)
            }
          
        }
        
      }, [cartItemCounter, location, fetchLoaclly])
    useEffect(() => {
        if (location.pathname === '/') {
            setHome(true)
            setCategory(false)
            setDonations(false)
            setProfile(false)
            setBag(false)
        } else if (location.pathname === '/donations') {
            setHome(false)
            setCategory(false)
            setDonations(true)
            setProfile(false)
            setBag(false)
        } else if (location.pathname === '/categories') {
            setHome(false)
            setCategory(true)
            setDonations(false)
            setProfile(false)
            setBag(false)
        } else if (location.pathname === '/mybag') {
            setHome(false)
            setCategory(false)
            setDonations(false)
            setProfile(false)
            setBag(true)
        } else if (location.pathname === '/profile' | location.pathname === '/auth') {
            setHome(false)
            setCategory(false)
            setDonations(false)
            setProfile(true)
            setBag(false)
        }
    }, [location])
    const goTo = (str) => {
        history.push(str)
    }

    return (
        <div className='FooterForMob'>
            <button onClick={()=> history.push('/')} className='navi_footer_mob_btn'>
                <div className='btnActive'>
                    <img style={home ? { filter: 'grayscale(0%)' } : { filter: 'grayscale(100%)' }} src={HomeRed} className='footer_mob_img' alt='home' />
                    <img style={home ? { display: 'block' } : { display: 'none' }} src={ball} className='ball' alt='home' />
                </div>
            </button>
            <button onClick={()=> history.push('/categories')} className='navi_footer_mob_btn'>
                <div className='btnActive'>
                    <img style={category ? { filter: 'grayscale(0%)' } : { filter: 'grayscale(100%)' }} src={CategoryRed} className='footer_mob_img' alt='home' />
                    <img style={category ? { display: 'block' } : { display: 'none' }} src={ball} className='ball' alt='home' />
                </div>
            </button>
            <button onClick={()=> history.push('/donations')} className='navi_footer_mob_btn'>
                <div className='btnActive'>
                    <img style={donations ? { filter: 'grayscale(0%)' } : { filter: 'grayscale(100%)' }} src={DonationRed} className='footer_mob_img' alt='home' />
                    <img style={donations ? { display: 'block' } : { display: 'none' }} src={ball} className='ball' alt='home' />
                </div>
            </button>
            <button onClick={()=> history.push('/mybag')} className='navi_footer_mob_btn' style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <div className='btnActive' style={{width:'30px', height:'30px', position:'relative'}}>
                    <img style={bag ? { filter: 'grayscale(0%)'} : { filter: 'grayscale(100%)'}} src={BagRed} className='footer_mob_img' alt='home' />
                    <span style={{color:'white', right:'-20px', top:'3px', fontFamily:'english_font'}} className="position-absolute translate-middle badge rounded-pill bg-danger footerCartNumber">
                      {
                        cartItemsCount
                      }
                    </span> 
                    <img style={bag ? { display: 'block' } : { display: 'none' }} src={ball} className='ball' alt='home' />
                </div>
            </button>
            <button onClick={()=> history.push(userLogin ? '/profile' : '/auth')} className='navi_footer_mob_btn'>
                <div className='btnActive'>
                    <img style={profile ? { filter: 'grayscale(0%)' } : { filter: 'grayscale(100%)' }} src={ProfileRed} className='footer_mob_img' alt='home' />
                    <img style={profile ? { display: 'block' } : { display: 'none' }} src={ball} className='ball' alt='home' />
                </div>
            </button>
        </div>
    )
}

export default FooterForMob