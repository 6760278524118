/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./components/LoaclComponents/header/header";
import HomePage from "./components/homePage/homepage";
import Footer from "./components/LoaclComponents/footer/footer";
import AllSukukPage from "./components/allSukukPage/AllSukukPage";
import MyBag from "./components/bagPage/MyBag";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrphanSliders } from "./actions/orphanSliders";
import { getFullProjects } from "./actions/projects";
import { getCompaginSliders } from "./actions/compaginsSliders";
import { getDonations } from "./actions/donations";
import { getHumanCases } from "./actions/humanCases";
import { getProjectShares, getShareProjects } from "./actions/projectShares";
import { getAllEvents } from "./actions/events";
import { getSukuk } from "./actions/sukuk";
import FavoritesPage from "./components/favoritesPage/FavoritesPage";
import Categories from "./components/categoriesPage/CategoriesPage";
import LandingPage from "./components/LandingPage/LandingPage";
import { getOrphans } from "./actions/countriesForOrphan";
import {
  checkingUserRegisterSteps,
  getMarriage,
  getVolunteerEvents,
  getVolunteerJobsNew,
} from "./actions/volunteer";
import CheckOutPage from "./components/checkOutPage/CheckOutPage";
import Profile from "./components/userPage/Profile";
import SponsershipsPage from "./components/sponsershipsPage/SponsershipsPage";
import LatePaymentRenewals from "./components/LatePaymentRenewals/LatePaymentRenewals";
import TransactionHistory from "./components/TransactionHistory/TransactionHistory";
import { initReactI18next } from "react-i18next";
import WebFont from "webfontloader";
import i18n, { use } from "i18next";
import { TRANSLATIONS_ARAB } from "./translations/arab/translations";
import { TRANSLATIONS_EN } from "./translations/en/translations";
import FooterForMob from "./components/LoaclComponents/footer/FooterForMob/FooterForMob";
import VolunteerPage from "./components/volunteerPage/VolunteerPage";
import LocalAidMainPage from "./components/LocalAidMainPage/LocalAidMainPage";
import DonationsPage from "./components/DonationsPage/DonationsPage";
import HumanCasesAllPage from "./components/humanCasesAllPage/HumanCasesAllPage";
import ProjectsAllPage from "./components/projectsAllPage/ProjectsAllPage";
import BeAsponserPage from "./components/beASponserPage/BeASponserPage";
import Auth from "./components/Auth/Auth";
import Settings from "./components/settings/Settings";
import WelcomeModal from "./welcomeModal/WelcomeModal";
import AboutUs from "./components/aboutUs/AboutUs";
import NotFound from "./components/NotFound/NotFound";
import LoadingModal from "./components/Auth/loginSignPage/loadingPopUp/loadingPopUp";
import Partenter from "./components/partener/Partenter";
import RedirectForMob from "./components/RedirectForMob/RedirectForMob";

import logo from "./assets/favicon.png";
import Mission from "./components/mission/Mission";
import ContactUs from "./components/contactUs/ContactUs";
import OurLocations from "./components/OurLocations/OurLocations";
import { getAboutUS, getAllMapLocations } from "./actions/brachLocation";
import Media from "./components/media/Media";
import News from "./components/media/news/News";
import Gallery from "./components/media/gallery/Gallery";
import GraceConversation from "./components/grace_conversation/GraceConversation";
import GraceRequest from "./components/grace_conversation/request/GraceRequest";
import PaymentMethod from "./components/userPage/payment_method/PaymentMethod";
import AddPaymentMethod from "./components/userPage/add_payment_method/AddPaymentMethod";
import EditProfile from "./components/userPage/EditProfile/EditProfile";
import SendGift from "./components/sendgift/SendGift";
import Guest from "./components/guest/Guest";
import {
  getSPDonationSummary,
  getSPNotifications,
  getSPAllLatepayments,
  getSPTransactionSummary,
} from "./actions/user";
import { getAllBranches, getCountriesForVolunteers } from "./actions/volunteer";
import SPPDF from "./components/sponsership_pdf/SPPDF";
import { getItemsFromBag, getItemsFromFavorites } from "./actions/BagFav";
import { Offline, Online } from "react-detect-offline";
import FinalReciept from "./components/FinalReciept/FinalReciept";
import { LOADING, SHOW_TUTORIAL } from "./constants/actionTypes";
import SwitchProjects from "./components/sponsershipsPage/switchProjectsDisplay/SwitchProjects";
import SwitchDonations from "./components/sponsershipsPage/switchDonationsDisplay/SwitchDonations";
import user from "./reducers/user";
import { toastifyMessage } from "./handlers/toastifyMessage";
import { baseURL, showAdvertisement } from "./api";
import Advertisement from "./components/Advertisement/Advertisement";
import getBrowserFingerprint from "get-browser-fingerprint";
import EventCalendar from "./components/homePage/sections/events/EventCalendar/EventCalendar";
import TutorialModel from "./components/TutorialModal/TutorialModel";
import { getAllTutorials } from "./actions/tutorials";
import ProjectShareRedirect from "./components/ProjectShareRedirect/ProjectShareRedirect";
import ProjectShareRoute from "./components/projectShare/ProjectShareRoute";
import SwitchProjectShares from "./components/sponsershipsPage/switchProjectSharesDisplay/SwitchProjectShares";
import { getLandingPages } from "./actions/landingPages";
import CourseNew from "./components/Course/CourseNew";
import EventAttendance from "./components/EventsAttentnce/EventAttendance";
import EventJoin from "./components/EventJoin/EventJoin";
import DonateIdea from "./components/donateIdea/DonateIdea";
import DonateEstate from "./components/donateEstate/DonateEstate";
const browserFingerprint = getBrowserFingerprint();

let allTextTypes = [
  "label",
  "a",
  "button",
  "div",
  "li",
  "p",
  "text",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "span",
];
const resources = {
  en: {
    translation: TRANSLATIONS_EN,
  },
  arab: {
    translation: TRANSLATIONS_ARAB,
  },
};
let languageFromLocal = JSON.parse(localStorage.getItem("language"));
i18n.use(initReactI18next).init({
  resources,
  lng: languageFromLocal ? languageFromLocal.lang : "arab",
});
function App() {
  const showTutorial = useSelector(
    (state) => state.tutorials_load.showTutorial
  );
  const [changeOfLanguage, setChangeOfLanguage] = useState(0);
  const [advertShow, setAdvertShow] = useState(false);
  const [advertDetails, setAdvertDetails] = useState(null);
  let languageFromLocal = JSON.parse(localStorage.getItem("language"));
  if (window.innerWidth >= 900 && !languageFromLocal) {
    setChangeOfLanguage(changeOfLanguage + 1);
    localStorage.setItem("language", JSON.stringify({ lang: "arab" }));
  }
  const loadingStatus = useSelector((state) => state.loadingState);
  const [loadingModalShow, setLoadingModalShow] = useState(
    loadingStatus?.loading
  );
  useEffect(() => {
    for (let i = 0; i < allTextTypes.length; i++) {
      let allElements = document.getElementsByTagName(allTextTypes[i]);
      for (let j = 0; j < allElements.length; j++) {
        allElements[j].style.fontFamily = languageFromLocal?.lang
          ? languageFromLocal?.lang === "arab"
            ? "'arab_font'"
            : "english_font"
          : "english_font";
      }
    }
    let emailBtn = document.getElementById("engfont");
    let badgeElements = document.getElementsByClassName("badge");
    for (let i = 0; i < badgeElements.length; i++) {
      badgeElements[i].style.fontFamily = "english_font";
    }
    if (emailBtn) {
      emailBtn.style.fontFamily = "english_font";
    }
  }, [languageFromLocal?.lang]);
  const [welcomeModal, setWelcomeModal] = useState(false);
  const [langNumber, setLangNumber] = useState(
    JSON.parse(localStorage.getItem("language"))?.lang === "arab" ? 1 : 2
  );

  const [reload, setReload] = useState(false);
  const [deviceType, setDeviceType] = useState(
    window.innerWidth > 900 ? "WEBAPP" : "MOBAPP"
  );
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    if (width > 700 && deviceType === "MOBAPP") {
      setDeviceType("WEBAPP");
    } else if (width <= 700 && deviceType === "WEBAPP") {
      setDeviceType("MOBAPP");
    } else {
      return;
    }
  }, [width]);
  const [langString, setLangString] = useState("AR");
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  useEffect(() => {
    languageFromLocal = JSON.parse(localStorage.getItem("language"));
  }, [changeOfLanguage]);
  const [lang, setLang] = useState("arab");

  const dispatch = useDispatch();
  useEffect(() => {
    if (languageFromLocal) {
      i18n.changeLanguage(languageFromLocal.lang);
      if (languageFromLocal.lang === "arab") {
        setLangNumber(1);
        setLangString("AR");
      } else {
        setLangNumber(2);
        setLangString("EN");
      }
    } else {
      let delayInMilliseconds = 1500; //1 second
      setTimeout(function () {
        setWelcomeModal(true);
      }, delayInMilliseconds);
    }
  }, [changeOfLanguage]);
  useEffect(() => {
    if (reload) {
      window.location.reload();
    }
  }, [reload]);
  const sponID = useSelector((state) => {
    return state.auth.extraSponID;
  });
  // const device_type = width <= 700 ? "MOBAPP" : "WEBAPP";

  useEffect(() => {
    //dispatch({type: LOADING, payload:true})
    dispatch(getAboutUS(langNumber));
    dispatch(getAllMapLocations(langNumber));
    dispatch(getAllBranches(langNumber));
    dispatch(getCountriesForVolunteers(langNumber));
    dispatch(getOrphanSliders(langString));
    dispatch(getFullProjects(langNumber));
    dispatch(getCompaginSliders(langNumber, deviceType));
    dispatch(getDonations(langNumber, deviceType));
    dispatch(getHumanCases(langNumber));
    dispatch(getProjectShares(langNumber));
    dispatch(getShareProjects(langNumber));
    dispatch(getAllEvents(langNumber));
    dispatch(getAllTutorials(langNumber));
    dispatch(getSukuk(langNumber, deviceType));
    dispatch(getMarriage(langNumber));
    dispatch(getVolunteerJobsNew(langNumber));
    dispatch(getLandingPages(langNumber));

    if (userLogin) {
      dispatch(getSPNotifications(userLogin?.Results[0]?.email, langNumber));
      dispatch(getItemsFromBag(userLogin.Results[0]?.email, langNumber));
      dispatch(getItemsFromFavorites(userLogin.Results[0]?.email, langNumber));
      if (userLogin?.Results[0]?.sponser_id || !sponID) {
        dispatch(
          getSPAllLatepayments(userLogin?.Results[0]?.sponser_id, langNumber)
        );
      }
      if (
        userLogin?.Results[0]?.vln_id_flag === "2" &&
        userLogin?.Results[0]?.vln_id
      ) {
        dispatch(getVolunteerEvents(userLogin?.Results[0]?.vln_id, langNumber));
      }
    }
    dispatch(getOrphans(langNumber));
    if (userLogin?.Results[0]?.sponser_id) {
      // dispatch(
      //   getSPDonationSummary(
      //     userLogin?.Results[0]?.sponser_id,
      //     langNumber,
      //     deviceType
      //   )
      // );
      dispatch(
        getSPTransactionSummary(userLogin?.Results[0]?.sponser_id, langNumber)
      );
    }
    if (userLogin?.Results[0]?.vln_id) {
      dispatch(checkingUserRegisterSteps(userLogin?.Results[0]?.vln_id));
    }
  }, [langString, langNumber, dispatch, deviceType]);

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (params?.error_message) {
      toastifyMessage(params.error_message, undefined, "error");
    }
    if (window && document) {
      const script = document.createElement("script");
      const body = document.getElementsByTagName("body")[0];
      if (baseURL.includes("MOBAPP_V3_PROD"))
        script.src =
          "https://eu-gateway.mastercard.com/form/version/61/merchant/7008334/session.js";
      else
        script.src =
          "https://test-gateway.mastercard.com/form/version/61/merchant/TEST7008334/session.js";

      body.appendChild(script);
    }
    try {
      showAdvertisement({
        deviceId: browserFingerprint,
        language: languageFromLocal.lang == "eng" ? 2 : 1,
      }).then((res) => {
        setAdvertShow(res.data.status);
        setAdvertDetails(res.data.data);
      });
    } catch {}
  }, []);

  return (
    <div>
      <div className="App">
        <Header />
        <div className="pages_Wraper">
          <Switch>
            <Route path="/" component={HomePage} exact />
            <Route path="/donations" component={DonationsPage} />
            <Route path="/humancases" component={HumanCasesAllPage} />
            <Route path="/humancase" component={HumanCasesAllPage} />
            <Route path="/projects" component={ProjectsAllPage} />
            <Route path="/beasponser" component={BeAsponserPage} />
            <Route path="/sukuk" component={AllSukukPage} />
            <Route path="/favorites" component={FavoritesPage} />
            <Route path="/categories" component={Categories} />
            <Route path="/checkout" component={CheckOutPage} />
            <Route path="/mybag" component={MyBag} />
            <Route path="/profile" component={Profile} />
            <Route path="/auth" component={Auth} />
            <Route path="/volunteer" component={VolunteerPage} />
            <Route path="/localAid" component={LocalAidMainPage} />
            <Route path="/settings" component={Settings} />
            <Route path="/sponsership/followups" component={SPPDF} />
            <Route path="/sponsership" component={SponsershipsPage} />
            <Route path="/sponsor-projects" component={SwitchProjects} />
            <Route path="/donate-idea" component={DonateIdea} />
            <Route path="/donate-estate" component={DonateEstate} />
            <Route
              path="/sponsor-shared-projects"
              component={SwitchProjectShares}
            />
            <Route path="/sponsor-donations" component={SwitchDonations} />
            <Route path="/course" component={CourseNew} />
            <Route path="/events-attendance" component={EventAttendance} />
            <Route path="/events-join" component={EventJoin} />
            <Route path="/renewals" component={LatePaymentRenewals} />
            <Route path="/about" component={AboutUs} />
            <Route path="/our-mission" component={Mission} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/our-locations" component={OurLocations} />
            <Route path="/media" component={Media} />
            <Route path="/news" component={News} />
            {/* {<Route path="/gallery" component={Gallery} />} */}
            <Route
              path="/grace_conversations/request"
              component={GraceRequest}
            />
            <Route path="/grace-conservation" component={GraceConversation} />
            <Route path="/payment-method" component={PaymentMethod} />
            <Route path="/add-payment-method" component={AddPaymentMethod} />
            <Route path="/editprofile" component={EditProfile} />
            <Route path="/sendgift" component={SendGift} />
            <Route path="/guest" component={Guest} />
            <Route path="/receipt/:or" component={FinalReciept} />
            <Route path="/our-partners" component={Partenter} />
            <Route path="/redirect" component={RedirectForMob} />
            <Route path="/transaction-history" component={TransactionHistory} />
            <Route path="/events_calendar" component={EventCalendar} />
            <Route path="/projectshare" component={ProjectShareRedirect} />
            <Route path="/project_share" component={ProjectShareRoute} />
            <Route path="/landingpages" component={LandingPage} />
            <Route component={NotFound} />
          </Switch>
        </div>

        <Footer />
        <FooterForMob />
        <TutorialModel
          show={showTutorial}
          dialogClassName="modal-60w"
          backdrop="static"
          onHide={() => {
            setReload(true);
          }}
        />
        <WelcomeModal
          show={welcomeModal}
          dialogClassName="modal-60w"
          backdrop="static"
          onHide={() => {
            setReload(true);
          }}
        />
        <Advertisement
          show={advertShow && advertDetails}
          advert={advertDetails}
          dialogClassName="modal-60w"
          backdrop="static"
          onHide={() => {
            setAdvertShow(false);
          }}
        />

        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
      </div>
    </div>
  );
}

export default App;
