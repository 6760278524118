import { handleRegularPayment } from './handleRegularPayment';

export const handleCardPayment = (body, dispatch, tokenToSave, recieptFor) => {
  var backend_body =
  {
    bag: body.cart,
    tokn: body.tokn,
    // payment_type: body.payment_type === "SAMECARD" ? "SAMECARD" : "DIRECT"
    payment_type: body.payment_type === "SAMECARD" ? "SAMECARD" : "NEWCARD",
    captcha:body?.captcha
  }
  return handleRegularPayment(backend_body, dispatch, tokenToSave, recieptFor);
}