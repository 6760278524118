/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./SponsorPage.css";
import bc from "../../../assets/loginBCPure.jpg";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Mousewheel, Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import donateIcon from "../../../assets/redDonateIcon.png";
import cash from "../../../assets/donateByCash.png";
import { useTranslation } from "react-i18next";
import likeIcon from "../../../assets/favour.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import samPay from "../../../assets/Pay_button_basic_pos_RGB.png";
import { startApplePay } from "../../../actions/handleApplePayment";
import Loading from "../../ui/loading";
import likedIcon from "../../../assets/favourite.png";
import editIcon from "../../../assets/pen-to-square-solid.svg";
import share from "../../../assets/share.png";
import { addToBag } from "../../../actions/BagFav";
import CountryProComp from "../../projectsAllPage/projectsPage/sections/countriesDisplay/countryProjectComp/CountryProComp";
import { handleSamsungDirectPayment } from "../../../actions/handleSamsungPayment";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
import DonationFinalPopUp from "../../checkOutPage/DonationFinalPopUp/DonationFinalPopUp";
import SponsorTerms from "../SponsorTerms/SponsorTerms";
import ShareAlertModel from "../../LoaclComponents/shareHeader/share_model/ShareModelAlert";
import { toastifyMessage } from "../../../handlers/toastifyMessage";
import { addToFavorites, deleteFromFavorites } from "../../../actions/BagFav";
import LoadingModal from "../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import { FETCH_LOCALLY } from "../../../constants/actionTypes";
import DonateBehalf from "./components/DonateBehalf";
SwiperCore.use([Mousewheel, Navigation]);

const SponsorPage = (props) => {
  const _isMounted = useRef(true);
  useEffect(() => {
    return () => {
        _isMounted.current = false;
    }
  }, []);
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [geustModal, setGuestModal] = useState(false);
  const [functionToSend, setFunctionToSend] = useState(() => {});
  const vlnBranches = useSelector((state) => state.vlnBranches);
  const [workPlace, setWorkPlace] = useState(vlnBranches);
  useEffect(() => {
    setWorkPlace(vlnBranches);
  }, [vlnBranches]);
  let { id } = useParams();
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let visitor = JSON.parse(localStorage.getItem("visitorDetails"));
  const [modalShow, setModalShow] = useState(false);
  const { pathname } = useLocation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  let currentProfile = JSON.parse(localStorage.getItem("profile"));
  const orphanSliders = useSelector(
    (state) => state.countriesForOrphan.countriesForOrphan
  );
  const orphanSlidersLoading = useSelector(
    (state) => state.countriesForOrphan.loading
  );
  const [ORPHANSLOADING, setORPHANSLOADING] = useState(orphanSlidersLoading);
  useEffect(() => {
    setORPHANSLOADING(orphanSlidersLoading);
  }, [orphanSlidersLoading]);

  const userFav = useSelector((state) => state.favorites.favorites);
  const [favoritesDetails, setFavoritesDetails] = useState(userFav);
  useEffect(() => {
    setFavoritesDetails(userFav);
  }, [userFav]);
  const [addingToFav, setAddingToFav] = useState(false);
  const [termsPop, setTermsPop] = useState(false);

  const [liked, setLiked] = useState(false);
  const [shareUrl, setShareUrl] = useState(false);
  const [copied, setCopied] = useState(false);
  const countriesForOrphan = useSelector(
    (state) => state.countriesForOrphan.countriesForOrphan
  );
  const userBag = useSelector((state) => state.bag.bag);
  const [bagDetails, setBagDetails] = useState(userBag);
  useEffect(() => {
    setBagDetails(userBag);
  }, [userBag]);
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const [showCopyCard, setshowCopyCard] = useState(false);
  const [displayied, setDisplayed] = useState([]);
  const [inTheBagText, setInTheBagText] = useState("Add to Bag");
  const [animationClass, setAnimationClass] = useState("btn_with_text");
  const [displayiedCountries, setDisplayedCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedOrphan, setSelectedOrphan] = useState("");
  const [display, setDisplay] = useState(0);
  const [inTheBag, setInTheBag] = useState(0);
  const [change, setChange] = useState(0);
  const [acceptedTerms, setAcceptedTerms] = useState(true);
  const [isDonatedBehalf, setIsDonatedBehalf] = useState(false);
  const [donateBehalfName, setDonateBehalfName] = useState("");

  const [monthlyAmout, setMonthlyAmount] = useState("");
  const [yearlyAMount, setYearlyAmount] = useState("");

  const [intialAmount, setIntialAmount] = useState(200);

  const [orphansData, setOrphansData] = useState({
    orphanId: "",
    orphanImg: "",
    orphanName: "",
    orphanCountryId: "",
    orphanCountryName: "",
    orphanCountryImg: "",
    paymentWay: "N",
    years: 0.5,
    donationAmountFromUser: 200,
    item_type: 1,
    workPlaceID: "1",
  });
  const [addingToCart, setAddingToCart] = useState(false);
  const fetchLoaclly = useSelector((state) => state.fetchLocallyState);
  useEffect(() => {
    currentProfile = JSON.parse(localStorage.getItem("profile"));
  }, [change, fetchLoaclly]);

  useEffect(() => {
    if (!userLogin) {
      if (
        currentProfile?.liked?.orphans?.filter(
          (item) =>
            item.orphanId === selectedOrphan &&
            item.orphanCountryId === selectedCountry
        ).length > 0
      ) {
        setLiked(true);
      } else {
        setLiked(false);
      }
    } else {
      if (
        favoritesDetails?.orphans?.filter(
          (item) =>
            item.orphanTypeID === selectedOrphan &&
            item.countryID === selectedCountry
        ).length > 0
      ) {
        setLiked(true);
      } else {
        setLiked(false);
      }
    }
    if (!userLogin) {
      if (
        currentProfile?.cart?.orphans?.filter(
          (item) =>
            item.orphanId === selectedOrphan &&
            item.orphanCountryId === selectedCountry
        ).length > 0
      ) {
        setInTheBag(
          currentProfile?.cart?.orphans?.filter(
            (item) =>
              item.orphanId === selectedOrphan &&
              item.orphanCountryId === selectedCountry
          ).length
        );
      }
    } else {
      if (
        bagDetails?.orphans?.filter(
          (item) =>
            item.orphanTypeID === selectedOrphan &&
            item.countryID === selectedCountry
        ).length > 0
      ) {
        let arr = bagDetails?.orphans?.filter(
          (item) =>
            item.orphanTypeID === selectedOrphan &&
            item.countryID === selectedCountry
        );
        let amountsArr = arr.map((item) => parseInt(item.quantity));
        let sumOfAll = amountsArr.reduce((x, y) => x + y, 0);
        setInTheBag(sumOfAll);
      }
    }
  }, [
    currentProfile,
    userLogin,
    bagDetails,
    selectedCountry,
    selectedOrphan,
    favoritesDetails,
  ]);

  useEffect(() => {
    const orphanCountryItem = countriesForOrphan
      ?.filter(
        (item) =>
          item?.orptypeID === selectedOrphan &&
          item?.countryID === selectedCountry
      )
      .slice(0, 1)
      .shift();
    setOrphansData({
      ...orphansData,
      donationAmountFromUser: parseInt(orphanCountryItem?.amount),
    });
    setIntialAmount(parseInt(orphanCountryItem?.amount));
  }, [selectedCountry]);
  // useEffect(()=> {
  //     window.scrollTo(0, 0);
  // })
  useEffect(() => {
    let projectsIDS = orphanSliders.map((item) => item.orptypeID);
    let uniqueProjects = [];
    for (let i = 0; i < orphanSliders.length; i++) {
      projectsIDS.splice(projectsIDS.indexOf(orphanSliders[i].orptypeID), 1);
      if (projectsIDS.indexOf(orphanSliders[i].orptypeID) < 0)
        uniqueProjects.push(orphanSliders[i]);
    }
    let testarrOfSort = uniqueProjects?.slice(0);
    if (testarrOfSort?.filter((item) => item.orptypeID === id).length === 0) {
      id = testarrOfSort.slice(0).shift()?.orptypeID;
    }
    setSelectedOrphan(id);
    let arrOfSort = uniqueProjects?.slice(0);
    let sortedItem = arrOfSort
      ?.filter((item) => item.orptypeID === id)
      .slice(0)
      .shift();
    // let arrayOfSortedFirst = arrOfSort?.filter(item => item.orptypeID !== id)
    // arrayOfSortedFirst?.unshift(sortedItem)
    setDisplayed(uniqueProjects?.slice(0));
    const allDataForOrphan = countriesForOrphan?.filter(
      (item) => item.orptypeID === id
    );
    const countriesForOrphanFinal = allDataForOrphan?.map((item) => {
      return {
        countryName: item.countryName,
        countryID: item.countryID,
        countryImagePath: item.countryImagePath,
        amount: item.amount,
      };
    });
    setDisplayedCountries(countriesForOrphanFinal);
    setOrphansData({
      orphanId: sortedItem?.orptypeID,
      orphanImg: sortedItem?.orptypeImagePath,
      orphanName: sortedItem?.orptypeName,
      orphanCountryId: countriesForOrphanFinal[0]?.countryID,
      orphanCountryName: countriesForOrphanFinal[0]?.countryName,
      orphanCountryImg: countriesForOrphanFinal[0]?.countryImagePath,
      paymentWay: "N",
      years: 0.5,
      donationAmountFromUser: parseInt(countriesForOrphanFinal[0]?.amount),
      item_type: 1,
      workPlaceID: "1",
    });
    setMonthlyAmount(parseInt(countriesForOrphanFinal[0]?.amount));
    setYearlyAmount(parseInt(countriesForOrphanFinal[0]?.amount) * 12 * 0.5);
    setIntialAmount(parseInt(countriesForOrphanFinal[0]?.amount));

    if (location.state?.selectedCountry) {
      setSelectedCountry(location.state?.selectedCountry);
    } else {
      setSelectedCountry(countriesForOrphanFinal[0]?.countryID);
    }
  }, [id, orphanSliders, countriesForOrphan]);

  const handleClick = (str) => {
    //let arrOfSort = orphanSliders?.slice(0);
    let projectsIDS = orphanSliders.map((item) => item.orptypeID);
    let uniqueProjects = [];
    for (let i = 0; i < orphanSliders.length; i++) {
      projectsIDS.splice(projectsIDS.indexOf(orphanSliders[i].orptypeID), 1);
      if (projectsIDS.indexOf(orphanSliders[i].orptypeID) < 0)
        uniqueProjects.push(orphanSliders[i]);
    }
    let itemWeNeed = uniqueProjects
      .filter((item) => item.orptypeID === str)
      .slice(0)
      .shift();

    // uniqueProjects = uniqueProjects.filter(item => item.orptypeID !== str)
    // uniqueProjects.unshift(itemWeNeed)
    setDisplayed([...uniqueProjects]);
    setSelectedOrphan(str);
    const allDataForOrphan = countriesForOrphan?.filter(
      (item) => item.orptypeID === str
    );
    const countriesForOrphanFinal = allDataForOrphan?.map((item) => {
      return {
        countryName: item.countryName,
        countryID: item.countryID,
        countryImagePath: item.countryImagePath,
        amount: item.amount,
      };
    });
    setDisplayedCountries(countriesForOrphanFinal);
    setOrphansData({
      orphanId: itemWeNeed?.orptypeID,
      orphanImg: itemWeNeed?.orptypeImagePath,
      orphanName: itemWeNeed?.orptypeName,
      orphanCountryId: countriesForOrphanFinal[0]?.countryID,
      orphanCountryName: countriesForOrphanFinal[0]?.countryName,
      orphanCountryImg: countriesForOrphanFinal[0]?.countryImagePath,
      paymentWay: "N",
      years: 0.5,
      donationAmountFromUser: parseInt(countriesForOrphanFinal[0]?.amount),
      item_type: 1,
      workPlaceID: "1",
    });
    setMonthlyAmount(parseInt(countriesForOrphanFinal[0]?.amount));
    setYearlyAmount(parseInt(countriesForOrphanFinal[0]?.amount) * 12 * 0.5);
    setIntialAmount(parseInt(countriesForOrphanFinal[0]?.amount));
    setSelectedCountry(countriesForOrphanFinal[0]?.countryID);
  };

  const handleSoiner = (str) => {
    setOrphansData({
      ...orphansData,
      years: str,
      paymentWay: "N",
      donationAmountFromUser: parseInt(intialAmount),
    });
    setYearlyAmount(parseInt(intialAmount) * 12 * str);
    setMonthlyAmount(parseInt(intialAmount));
    setDisplay(0);
  };
  const [dontDisplayBecauseOfBuild, setDontDisplayBecauseOfBuild] =
    useState(false);

  const [monthlyEdit, setMonthlyEdit] = useState(false);
  const [yearlyEdit, setYearlyEdit] = useState(false);
  const handlePaymentMethod = (str) => {
    setMonthlyEdit(false);
    setYearlyEdit(false);
    if (str === "full") {
      setOrphansData({ ...orphansData, paymentWay: "N" });
    } else {
      setOrphansData({ ...orphansData, paymentWay: "Y" });
    }
  };
  const [view, setView] = useState(5);
  const [view2, setView2] = useState(5);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    if (window.innerWidth > 3400 && window.innerWidth <= 4000) {
      setView(15);
      setView2(15);
    } else if (window.innerWidth > 2530 && window.innerWidth <= 3400) {
      setView(11);
      setView2(13);
    } else if (window.innerWidth > 1860 && window.innerWidth <= 2530) {
      setView(8.3);
      setView2(10);
    } else if (window.innerWidth > 1400 && window.innerWidth <= 1860) {
      setView(6.5);
      setView2(7.1);
    } else if (window.innerWidth > 1111 && window.innerWidth <= 1400) {
      setView(5);
      setView2(5.4);
    } else if (window.innerWidth > 900 && window.innerWidth <= 1111) {
      setView(4);
      setView2(4.5);
    } else if (window.innerWidth > 700 && window.innerWidth <= 900) {
      setView(5);
      setView2(4.3);
    } else if (window.innerWidth > 550 && window.innerWidth <= 700) {
      setView(4);
      setView2(3);
    } else if (window.innerWidth > 400 && window.innerWidth <= 550) {
      setView(2.9);
      setView2(3);
    } else if (window.innerWidth > 280 && window.innerWidth <= 400) {
      setView(2);
      setView2(3);
    } else if (window.innerWidth > 210 && window.innerWidth <= 280) {
      setView(1.3);
      setView2(2.2);
    } else {
      setView(1.1);
      setView2(1.8);
    }
  }, [width]);
  const handleLike = async (donationID) => {
    setAddingToFav(true);
    if (!userLogin) {
      if (!currentProfile) {
        localStorage.setItem(
          "profile",
          JSON.stringify({
            cart: {
              donations: [],
              sukuks: [],
              projects: [],
              humanCases: [],
              orphans: [],
            },
            liked: { donations: [], sukuks: [], projects: [], orphans: [] },
          })
        );
        currentProfile = JSON.parse(localStorage.getItem("profile"));
      }
      currentProfile?.liked?.orphans?.push(donationID);
      const newProfile = {
        cart: currentProfile.cart,
        liked: currentProfile.liked,
      };
      localStorage.setItem("profile", JSON.stringify(newProfile));
      await dispatch({ type: FETCH_LOCALLY });
      setChange(change + 1);
      if (_isMounted.current) {
        setAddingToFav(false);
      }
      
    } else {
      await dispatch(
        addToFavorites(lng === "arab" ? 1 : 2, {
          userEmail: userLogin.Results[0]?.email,
          dontypeID: "",
          projectID: "",
          orptypeID: selectedOrphan,
          projectYear: "",
          countryID: selectedCountry,
        })
      );
      if (_isMounted.current) {
        setAddingToFav(false);
      }
    }
  };
  const handleDisLike = async (donationID) => {
    setAddingToFav(true);
    if (!userLogin) {
      let newProfile = {
        cart: currentProfile.cart,
        liked: {
          donations: currentProfile.liked.donations,
          sukuks: currentProfile.liked.sukuks,
          projects: currentProfile.liked.projects,
          orphans: currentProfile.liked.orphans.filter(
            (item) =>
              !(
                item.orphanId === donationID.orphanId &&
                item.orphanCountryId === donationID.orphanCountryId
              )
          ),
        },
      };
      localStorage.setItem("profile", JSON.stringify(newProfile));
      await dispatch({ type: FETCH_LOCALLY });
      setChange(change + 1);
      if (_isMounted.current) {
        setAddingToFav(false);
      }
      
    } else {
      let elementWeNeed = favoritesDetails?.orphans
        ?.filter(
          (item) =>
            item.orphanTypeID === selectedOrphan &&
            item.countryID === selectedCountry
        )
        .slice(0, 1)
        .shift();
      if (elementWeNeed) {
        await dispatch(
          deleteFromFavorites(
            lng === "arab" ? 1 : 2,
            userLogin.Results[0]?.email,
            elementWeNeed.cart_serial
          )
        );
      }
      if (_isMounted.current) {
        setAddingToFav(false);
      }
    }
  };
  const handleLikeDisLike = (str) => {
    if (liked) {
      handleDisLike(str);
    } else {
      handleLike(str);
    }
  };
  const handleInBag = async (str) => {
    setAddingToCart(true);

    if (!userLogin) {
      if (!currentProfile) {
        localStorage.setItem(
          "profile",
          JSON.stringify({
            cart: {
              donations: [],
              sukuks: [],
              projects: [],
              humanCases: [],
              orphans: [],
            },
            liked: { donations: [], sukuks: [], projects: [], orphans: [] },
          })
        );
        currentProfile = JSON.parse(localStorage.getItem("profile"));
      }
      currentProfile.cart.orphans.push({
        ...str,
        donationAmountFromUser:
          str.paymentWay === "N"
            ? parseInt(str.donationAmountFromUser) * str.years * 12
            : parseInt(str.donationAmountFromUser),
        dateOfAdding: new Date().getTime(),
        baseAmount: parseInt(str.donationAmountFromUser),
      });
      const newProfile = {
        cart: currentProfile.cart,
        liked: currentProfile.liked,
      };
      localStorage.setItem("profile", JSON.stringify(newProfile));
      await dispatch({ type: FETCH_LOCALLY });
      toastifyMessage("Added!", undefined, "success");
      setChange(change + 1);
    } else {
      await dispatch(
        addToBag({
          cart: {
            donID: "1",
            userName: userLogin.Results[0]?.name_a,
            userMob: userLogin.Results[0]?.mobile_no,
            userEmail: userLogin.Results[0]?.email,
            itemType: "1",
            amount: str.donationAmountFromUser,
            recuring: str.paymentWay,
            cart_serial: null,
            orpTypeID: selectedOrphan,
            workPlaceID: orphansData.workPlaceID,
            country_id: str.orphanCountryId,
            month_count: str.paymentWay === "N" ? str.years * 12 : 1,
          },
          language: lng === "arab" ? 1 : 2,
        })
      );
    }
    if (_isMounted.current) {
      setAnimationClass("btn_with_text animate__animated animate__bounceOut");
    setTimeout(() => {
      if (_isMounted.current) {
        setAnimationClass("btn_with_text animate__animated animate__bounceIn");
      setAddingToCart(false);

      setInTheBagText("Added");
      }
      
    }, 1000);
    setTimeout(() => {
      if (_isMounted.current) {
        setAnimationClass("btn_with_text");
      setInTheBagText("Add to Bag");
      }
      
      //setAnimationClass("btn_with_text animate__animated animate__bounceIn");
    }, 2500);
    }
    

    //}
  };

  useEffect(() => {
    let textOfPageArr = window.location.href.split("/");
    textOfPageArr.splice(textOfPageArr.length - 1, 1, selectedOrphan);
    setShareUrl(textOfPageArr.join("/"));
  }, [selectedOrphan]);

  let checkBoxStyle =
    lng === "arab" ? "rightSign_btn_arab--active" : "rightSign_btn--active";
  const startSamsungPay = async () => {
    setLoadingModalShow(true);
    await handleSamsungDirectPayment({
      amount:
        orphansData.paymentWay == "Y"
          ? orphansData.donationAmountFromUser
          : orphansData.donationAmountFromUser * (orphansData.years * 12),
      url: window.location.href,
      item: [
        {
          dontype_id: 1,
          amount: orphansData.donationAmountFromUser,
          item_type: 1,
          recuring: orphansData.paymentWay,
          month_count:
            orphansData.paymentWay == "Y" ? 1 : orphansData.years * 12,
          orptype_id: orphansData.orphanId,
          country_id: orphansData.orphanCountryId,
          workPlaceID: orphansData.workPlaceID,
        },
      ],
    });
    setLoadingModalShow(false);
  };

  const handleGuestPopUp = async (options) => {
    if (userLogin || visitor) {
      options();
    } else {
      setFunctionToSend(() => options);
      setGuestModal(true);
    }
  };
  const doneEditing = (str) => {
    if (str === "monthly") {
      if (
        parseInt(intialAmount) > parseInt(monthlyAmout) ||
        parseInt(intialAmount) <= 0 ||
        !intialAmount
      ) {
        return toastifyMessage(
          "The amount you selected is less than the intial amount",
          undefined,
          "warning"
        );
      } else {
        setOrphansData({
          ...orphansData,
          donationAmountFromUser: parseFloat(monthlyAmout),
        });
        setMonthlyEdit(false);
      }
    } else {
      if (
        parseInt(intialAmount) * orphansData.years * 12 >
          parseInt(yearlyAMount) ||
        parseInt(intialAmount) <= 0 ||
        !intialAmount
      ) {
        return toastifyMessage(
          "The amount you selected is less than the intial amount",
          undefined,
          "warning"
        );
      } else {
        setOrphansData({
          ...orphansData,
          donationAmountFromUser:
            parseInt(yearlyAMount) / (12 * orphansData.years),
        });
        setYearlyEdit(false);
      }
    }
  };

  const editAmount = (str) => {
    if (str === "monthly") {
      if (monthlyEdit) {
        setMonthlyEdit(false);
      } else {
        setMonthlyEdit(true);
      }
      setYearlyEdit(false);
    } else {
      if (yearlyEdit) {
        setYearlyEdit(false);
      } else {
        setYearlyEdit(true);
      }
      setMonthlyEdit(false);
    }
  };

  return (
    <div id="sponsor_page" style={lng === "arab" ? { direction: "rtl" } : null}>
      <ShareHeader
        backTwice={location.state?.selectedCountry ? true : false}
        textToBeCopied={shareUrl}
        pageName={t("Be a Sponsor")}
        path={"beasponser"}
        shareData={orphansData}
      />
      <div
        className="banner_sponser"
        style={{ position: "relative", width: "100%" }}
      >
        <img src={bc} alt="sponsor_banner" className="sponsor_banner" />
        <h1
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "50px",
            position: "absolute",
            top: "65%",
            width: "100%",
            textAlign: "center",
          }}
        >
          {t("Be a Sponsor")}
        </h1>
      </div>
      {ORPHANSLOADING ? (
        <Loading height="700px" />
      ) : (
        <React.Fragment>
          <div className="spin_orphan_sliders">
            <div id="donation_display_mobile" style={{ width: "100%" }}>
              <React.Fragment>
                <Swiper
                  slidesPerView={view}
                  navigation={true}
                  loop={displayied?.length > view ? true : false}
                >
                  {displayied?.map((item, index) => (
                    <SwiperSlide
                      className="orphan_sliders"
                      key={item?.orptypeID}
                    >
                      <div className="orphan_card_border">
                        <span
                          style={
                            lng === "arab"
                              ? { marginRight: "0px", marginLeft: "-2px" }
                              : null
                          }
                          id={`rightSign_btn_of_${item?.orptypeID}`}
                          className={
                            selectedOrphan === item?.orptypeID
                              ? checkBoxStyle
                              : "rightSign_btn"
                          }
                        >
                          <i className="fas fa-check"></i>
                        </span>
                        <div
                          className="orphan_btn_border"
                          style={
                            selectedOrphan === item?.orptypeID
                              ? { border: "2px solid #ee4236" }
                              : { border: "none" }
                          }
                        >
                          <div
                            disabled={addingToCart}
                            id={item?.orptypeID}
                            onClick={() => handleClick(item?.orptypeID)}
                            style={{ order: index + 1 }}
                            className="btn_img_wraper_become_a_spon"
                          >
                            <img
                              style={
                                selectedOrphan === item?.orptypeID
                                  ? { filter: "unset" }
                                  : { filter: "grayscale(100%)" }
                              }
                              id={`img_${item?.orptypeID}`}
                              src={item?.orptypeImagePath}
                              alt={item?.orptypeName}
                              className="btn_img_become_a_spon"
                            />
                            <label
                              id={`label_${item?.orptypeID}`}
                              className="btn_img_label_become_a_spon"
                            >
                              {item?.orptypeName}
                            </label>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </React.Fragment>
            </div>
          </div>
          <div className="country_sliders second">
            <h2 className="donation_favorites_title title_to_be_modify">
              {t("Choose a Country")}
            </h2>
            <div
              id="donation_display_mobile"
              disabled={addingToCart}
              style={{ width: "100%", padding: "20px" }}
            >
              <React.Fragment>
                <Swiper
                  slidesPerView={view2}
                  spaceBetween={10}
                  navigation={true}
                  loop={displayiedCountries?.length > view2 ? true : false}
                >
                  {displayiedCountries?.map((item, index) => (
                    <SwiperSlide
                      className="orphan_sliders_flags"
                      key={item?.countryID}
                    >
                      <CountryProComp
                        orphansData={orphansData}
                        setOrphansData={setOrphansData}
                        fromOrphan={true}
                        style={{ order: index + 1 }}
                        selected={selectedCountry}
                        setSelectedCountry={setSelectedCountry}
                        key={item?.countryID}
                        CountryId={item?.countryID}
                        ImagePath={item?.countryImagePath}
                        Name_En={
                          item?.countryName.length > 8
                            ? item?.countryName.slice(0, 7) + "."
                            : item?.countryName
                        }
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </React.Fragment>
            </div>
          </div>
          <div
            className="country_sliders second"
            style={{
              minHeight: "136px",
              padding:
                width > 900 ? "10px 40px 10px 40px" : "20px 10px 20px 10px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <h2 className="donation_favorites_title title_to_be_modify">
              {t("Choose a branch")}
            </h2>
            <select
              style={{
                // marginLeft: "45px",
                backgroundPositionX: lng === "arab" ? "0px" : "100%",
                width: width <= 900 ? "90%" : null,
              }}
              name="workPlaceID"
              value={orphansData.workPlaceID}
              onChange={(e) =>
                setOrphansData({ ...orphansData, workPlaceID: e.target.value })
              }
              className="select-issue select-form"
            >
              {workPlace?.map((item, i) => (
                <option key={i} value={item.workplace_id}>
                  {item.workplace_name}
                </option>
              ))}
            </select>
          </div>
          <div className="country_sliders first">
            <h2
              className="donation_favorites_title title_to_be_modify"
              style={{ fontWeight: "bold", width: "100%" }}
            >
              {t("Kindly Select the Payment Option")}
            </h2>
            <div className="be_a_sposer_payment_section">
              <div className="paymentMethodsWraper_orphans">
                <div
                  id="full_payment_for_mob"
                  className="years_become_a_sposer"
                  style={
                    orphansData.paymentWay === "N"
                      ? { border: "1px solid #ee4236" }
                      : null
                  }
                >
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOrphansData({ ...orphansData, paymentWay: "N" });
                      setMonthlyEdit(false);
                      setYearlyEdit(false);
                    }}
                    className="years_become_a_sposer_title"
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          addingToCart ? null : handlePaymentMethod("full")
                        }
                        className="padding_out"
                      >
                        <i className="far fa-money-bill-alt"></i>{" "}
                        {t("Full Payment")}
                      </label>
                      <label style={{ fontSize: "11px" }}>
                        {t("Months")}:{" "}
                        <span className="payment_numbers">
                          {orphansData.years * 12}
                        </span>
                      </label>
                    </div>
                    <button
                      disabled={addingToCart}
                      onClick={() =>
                        display === 0 ? setDisplay(1) : setDisplay(0)
                      }
                      className="btn_for_expand"
                    >
                      {display === 0 ? (
                        <i className="fas fa-sort-down"></i>
                      ) : (
                        <i className="fas fa-sort-up"></i>
                      )}
                    </button>
                  </div>
                  {display === 1 ? (
                    <div id="time">
                      <button
                        className="time_btn"
                        onClick={() => handleSoiner(0.5)}
                      >
                        {lng === "arab" ? "ستة أشهر" : "6 Months"}
                      </button>
                      <button
                        className="time_btn"
                        onClick={() => handleSoiner(1)}
                      >
                        {lng === "arab" ? "سنة واحدة" : "1 Year"}
                      </button>
                      <button
                        className="time_btn"
                        onClick={() => handleSoiner(2)}
                      >
                        {lng === "arab" ? "سنتان" : "2 Years"}
                      </button>
                      <button
                        className="time_btn"
                        onClick={() => handleSoiner(3)}
                      >
                        {lng === "arab" ? "ثلاث سنوات" : "3 Years"}
                      </button>
                      <button
                        className="time_btn"
                        onClick={() => handleSoiner(4)}
                      >
                        {lng === "arab" ? "أربع سنوات" : "4 Years"}
                      </button>
                      <button
                        className="time_btn"
                        onClick={() => handleSoiner(5)}
                      >
                        {lng === "arab" ? "خمس سنوات" : "5 Years"}
                      </button>
                      <button
                        className="time_btn"
                        onClick={() => handleSoiner(6)}
                      >
                        {lng === "arab" ? "ست سنوات" : "6 Years"}
                      </button>
                      <button
                        className="time_btn"
                        onClick={() => handleSoiner(7)}
                      >
                        {lng === "arab" ? "سبع سنوات" : "7 Years"}
                      </button>
                      <button
                        className="time_btn"
                        onClick={() => handleSoiner(8)}
                      >
                        {lng === "arab" ? "ثمان سنوات" : "8 Years"}
                      </button>
                      <button
                        className="time_btn"
                        onClick={() => handleSoiner(9)}
                      >
                        {lng === "arab" ? "تسع سنوات" : "9 Years"}
                      </button>
                      <button
                        className="time_btn"
                        onClick={() => handleSoiner(10)}
                      >
                        {lng === "arab" ? "عشر سنوات" : "10 Years"}
                      </button>
                    </div>
                  ) : null}
                  {display !== 1 ? (
                    <div
                      className="mobile_input_to_add_monthly_payment"
                      style={{
                        alignSelf: "center",
                        alignItems: "flex-end",
                        display: "flex",
                        justifyContent: "flex-start",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      {yearlyEdit ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "5px",
                          }}
                          className="payment_numbers"
                          className="aed_mob"
                        >
                          <span>{t("AED")}</span>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "2px",
                            }}
                            className='columnFlex'
                          >
                            <input
                              type="text"
                              pattern="[0-9]*"
                              value={yearlyAMount}
                              onChange={(e) =>
                                setYearlyAmount(
                                  e.target.value.replace(/\D/, "")
                                )
                              }
                              className='smallInput_smallDevice'
                            />
                            <button
                              onClick={() => doneEditing("yearly")}
                              style={{
                                width: "50px",
                                height: "30px",
                                backgroundColor: "#ee4236",
                                border: "none",
                                color: "white",
                                borderRadius: "5px",
                              }}
                            >
                              {t("Done")}
                            </button>
                          </div>
                        </div>
                      ) : (
                        <label className="payment_numbers" className="aed_mob">
                          {t("AED")}{" "}
                          <span className="payment_numbers">
                            {isNaN(parseInt(orphansData.donationAmountFromUser))
                              ? 0
                              : parseInt(orphansData.donationAmountFromUser) *
                                orphansData.years *
                                12}
                          </span>
                        </label>
                      )}
                      {yearlyEdit ? null : (
                        <button
                          onClick={() => editAmount("yearly")}
                          className='edit-button'
                          style={{
                            position: "absolute",
                            backgroundColor: "transparent",
                            border: "none",
                            left: lng === "arab" ? "5px" : "auto",
                            right: lng === "arab" ? "auto" : "5px",
                            width: "20px",
                            height: "20px",
                            top: "0px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={editIcon}
                            alt="edit_icon"
                            style={{ height: "20px", width: "20px" }}
                          />
                        </button>
                      )}
                    </div>
                  ) : null}
                </div>
                <div
                  id="full_payment_for_mob"
                  className="years_become_a_sposer"
                  style={
                    orphansData.paymentWay === "Y"
                      ? { border: "1px solid #ee4236" }
                      : null
                  }
                >
                  <div
                    style={{
                      cursor: "poniter",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={() => {
                      addingToCart ? null : handlePaymentMethod("monthly"),
                        setDisplay(0);
                    }}
                    className="years_become_a_sposer_title"
                  >
                    <label
                      style={{ cursor: "pointer", width: "100%" }}
                      className="padding_out"
                    >
                      <i className="fas fa-database"></i> {t("Monthly Payment")}
                    </label>
                    <span style={{ height: "17px" }}></span>
                  </div>
                  <div
                    className="mobile_input_to_add_monthly_payment"
                    style={{
                      alignSelf: "center",
                      alignItems: "flex-end",
                      display: "flex",
                      justifyContent: "flex-start",
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    {monthlyEdit ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                        className="payment_numbers"
                        className="aed_mob"
                      >
                        <span>{t("AED")}</span>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "2px",
                          }}
                          className='columnFlex'
                        >
                          <input
                            type="text"
                            pattern="[0-9]*"
                            value={monthlyAmout}
                            onChange={(e) =>
                              setMonthlyAmount(e.target.value.replace(/\D/, ""))
                            }
                            className='smallInput_smallDevice'
                          />
                          <button
                            onClick={() => doneEditing("monthly")}
                            style={{
                              width: "50px",
                              height: "30px",
                              backgroundColor: "#ee4236",
                              border: "none",
                              color: "white",
                              borderRadius: "5px",
                            }}
                            
                          >
                            {t("Done")}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <label className="payment_numbers" className="aed_mob">
                        {t("AED")}
                        <span className="payment_numbers">
                          {isNaN(orphansData.donationAmountFromUser)
                            ? 0
                            : Math.round(orphansData.donationAmountFromUser)}
                        </span>
                      </label>
                    )}
                    {monthlyEdit ? null : (
                      <button
                        onClick={() => editAmount("monthly")}
                        className='edit-button'
                        style={{
                          position: "absolute",
                          backgroundColor: "transparent",
                          border: "none",
                          left: lng === "arab" ? "5px" : "auto",
                          right: lng === "arab" ? "auto" : "5px",
                          width: "20px",
                          height: "20px",
                          top: "0px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={editIcon}
                          alt="edit_icon"
                          style={{ height: "20px", width: "20px" }}
                        />
                      </button>
                    )}
                  </div>
                  
                </div>
                
              </div>
              <div className='TermsAndCondSpon'>
              <div style={{display:'flex', alignItems:'center', gap: '10px'}}>
                  <input checked={acceptedTerms} onChange={()=> setAcceptedTerms(!acceptedTerms)} style={{marginBottom: '17px', height: '20px', width: '20px'}} type="checkbox" id="terms" name="terms"/>
                  <h4 onClick={()=> setTermsPop(true)} style={{cursor:'pointer'}} className="donation_favorites_title title_to_be_modify termsAndCondText" htmlFor="terms">{t("I agree to all terms and conditions of the sponsorship")}</h4>
              </div>
                <DonateBehalf setDonateBehalfName={setDonateBehalfName} donateBehalfName={donateBehalfName} width={width} lng={lng} t={t} setIsDonatedBehalf={setIsDonatedBehalf} isDonatedBehalf={isDonatedBehalf} />
              </div>
                            <div className='check_out_payment_method_btns'>
                                <div style={{ display: 'grid', gridTemplateColumns: window.innerWidth <= 900 ? "33% 33% 33%" : '25% 25% 25% 25%', alignItems: 'center', justifyContent: 'space-evenly', width: '100%' }}>
                                    <div className='btn_with_text shareBtnDesk' style={{ width: 'auto' }}>
                                        <button onClick={() => setshowCopyCard(true)} className='human_donate_ways btn1 shadowForBtn'><img src={share} alt='donate_btn_icon_banner' className='donate_btn_icon_ind' /></button>
                                        <label className='label_to_beSmall'>{t('Share')}</label>
                                    </div>
                                    <div className='btn_with_text ' style={{ width: 'unset' }}>
                                    {addingToFav?
                                        <button disabled={addingToFav} className=' btn4' style={{minHeight:'90px', minWidth:'90px'}}>
                                          <div className="containerAnime">
                                            <div style={{width:'10px', height:'10px'}} className="ballFav"></div>
                                            <div style={{width:'10px', height:'10px'}} className="ballFav"></div>
                                          </div>
                                        </button>:
                                        <button 
                                          onClick={() => 
                                            acceptedTerms? 
                                            handleLikeDisLike(orphansData):
                                            toastifyMessage("You Can't continue without accepting sponsorship terms and conditions",undefined,"warning")} 
                                          className='human_donate_ways btn1 shadowForBtn'><img src={liked ? likedIcon : likeIcon} alt='donate_btn_icon_banner' className='donate_btn_icon_ind' /></button>
                                    }
                                        <label className='label_to_beSmall' style={{ textAlign: 'center' }}>{liked ? t('Remove from favorites') : t("Add to favorites")}</label>
                                    </div>
                                    {inTheBagText === 'Added' ?
                                        <div className={'btn_with_text animate__animated animate__bounceIn'} style={{ width: 'unset' }}>
                                            <button disabled={addingToCart} className="human_donate_ways btn1 " style={{ backgroundColor: 'transparent' }}>
                                                <label style={{ fontSize: window.innerWidth <= 900 ? '7px' : '12px', color: '#ee4236' }} className='label_to_beSmall'>{t("You now have ")} <span className='payment_numbers'>{inTheBag}</span> {t(" in your Bag")}</label>
                                            </button>
                                        </div> :
                                        <React.Fragment>
                                            <div className={animationClass} style={{ width: 'unset' }}>
                                                {addingToCart ?
                                                    <Loading height={'110px'} mediaQ={'100px'} small={'40px'} animate={true} /> :
                                                    <React.Fragment>
                                                        <button disabled={addingToCart} onClick={() => 
                                                          acceptedTerms? 
                                                          handleInBag(orphansData):
                                                          toastifyMessage("You Can't continue without accepting sponsorship terms and conditions",undefined,"warning")
                                                          } className='human_donate_ways btn1 shadowForBtn'><img src={donateIcon} alt='donate_btn_icon_banner' className='donate_btn_icon_ind' /></button>
                                                        <label className='label_to_beSmall' style={{ textAlign: 'center' }}>{t(inTheBagText)}</label>
                                                    </React.Fragment>}
                                            </div>
                                        </React.Fragment>}

                                    <div className='btn_with_text ' style={{ width: 'unset' }}>
                                        <button onClick={() => 
                                        acceptedTerms? 
                                          history.push(`/checkout`, { from: 'orphans', elements: [{ title: orphansData.orphanName + ' ' + t("at") + ' ' + orphansData.orphanCountryName, price: orphansData.paymentWay === 'N' ? parseInt(orphansData.donationAmountFromUser) * orphansData.years * 12 : parseInt(orphansData.donationAmountFromUser) }], details: orphansData, totalAmount: orphansData.paymentWay === 'N' ? parseInt(orphansData.donationAmountFromUser) * orphansData.years * 12 : parseInt(orphansData.donationAmountFromUser) }):
                                          toastifyMessage("You Can't continue without accepting sponsorship terms and conditions",undefined,"warning")
                                        } className='human_donate_ways btn3 shadowForBtn'><img src={cash} alt='donate_btn_icon_banner' className='donate_btn_icon_ind' /></button>
                                        <label className='label_to_beSmall' style={{ textAlign: 'center' }}>{t("Donate Now")}</label>
                                    </div>
                                </div>
                                {window.ApplePaySession ? 
                                orphansData.paymentWay === 'Y' ?
                                    <div style={{width:'80%', opacity: "40%"}} id="applepay_btn" className="apple-pay-button-outside apple-pay-button-black apple_human_btn samApple_btn" locale="ar-AB" disabled></div> 
                                    : 
                                    <div style={{width:'80%'}} id="applepay_btn" className="apple-pay-button-outside apple-pay-button-black apple_human_btn samApple_btn" locale="ar-AB" onClick={() => 
                                      acceptedTerms? 
                                        handleGuestPopUp(()=>  startApplePay({
                                          amount: orphansData.paymentWay == 'Y' ? orphansData.donationAmountFromUser : orphansData.donationAmountFromUser * (orphansData.years * 12), cart: [{
                                          dontype_id: '1', itemType: 1, amount: orphansData.donationAmountFromUser, cart_serial: null, orpTypeID: selectedOrphan,
                                          month_count: orphansData.years * 12, recuring: orphansData.paymentWay, country_id: orphansData.orphanCountryId,
                                          workPlaceID: orphansData.workPlaceID
                                        }]
                                      }, dispatch, history)):
                                      toastifyMessage("You Can't continue without accepting sponsorship terms and conditions",undefined,"warning")
                                    }>
                                    </div> :
                                    orphansData.paymentWay === 'Y' ?
                                        <button disabled style={{ opacity: "40%", width: '80%', backgroundColor: 'black', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            className='btn btn-block btn-dark apple_human_btn width_for_orphans samApple_btn'><img className='samApple_btn_img' src={samPay} style={{ height: '60px' }} alt='samsung_pay' /></button>
                                        : <button style={{ width: '80%', backgroundColor: 'black', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            onClick={() => acceptedTerms? handleGuestPopUp(startSamsungPay):toastifyMessage("You Can't continue without accepting sponsorship terms and conditions",undefined,"warning")} className='btn btn-block btn-dark apple_human_btn width_for_orphans samApple_btn'><img className='samApple_btn_img' src={samPay} style={{ height: '60px', width:width<=300? '100%':'auto' }} alt='samsung_pay' /></button>
                                }
                                {/* {modalShow ? <Modal
                                    show={modalShow}
                                    size="sm"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    className='donation_pop_up_body'
                                >
                                    <Modal.Body style={{ backgroundColor: '#fdfdfd', borderRadius: '10px' }} >
                                        <div className='modal_body_for_donation_final' style={lng === 'arab' ? { direction: 'rtl' } : null}>
                                            <label>{t("Would you like to")} <span style={{ fontWeight: 'bold' }}>{t("Register")}</span> {t("for easier donations in the future?")}</label>
                                            <div className='donation_final_popUp_btns_wraper'>
                                                <button onClick={() => history.push('/guest')} className='donation_final_popUp_btn_first'>
                                                    {t("Not Now")}
                                                </button>
                                                <button onClick={() => history.push('/auth')} className='donation_final_popUp_btn_second'>
                                                    {t("Yes")}
                                                </button>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal> : null} */}
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      <DonationFinalPopUp
        show={geustModal}
        paymentfunction={functionToSend}
        onHide={() => setGuestModal(false)}
        dialogClassName="modal-60w"
      />
      <ShareAlertModel
        path="beasponser"
        shareData={orphansData}
        show={showCopyCard}
        onHide={() => {
          setshowCopyCard(false);
        }}
      ></ShareAlertModel>
      <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
      <SponsorTerms
        show={termsPop}
        dialogClassName="modal-90w"
        onHide={()=> setTermsPop(false)}
      />
      
    </div>
  );
};
export default SponsorPage;
