import React, { Fragment, useEffect, useState } from "react";
import "./course.scss";
import course_bg from "../../assets/course_bg.svg";
import logo from "../../assets/new_logo_course.svg";
import cartimage from "../../assets/course_cart.svg";
import map_pin from "../../assets/map-pin.svg";
import filter from "../../assets/filter.svg";
import calaneter from "../..//assets/calander.svg";
import time from "../../assets/time.svg";
import ShareHeader from "../LoaclComponents/shareHeader/ShareHeader";
import * as api from "../../api";
import { useTranslation } from "react-i18next";

const CourseNew = () => {
  const [courseList, setCourseList] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [filteredCourseList, setFilteredCourseList] = useState([]);
  const [shareUrl, setShareUrl] = useState(false);
  const { t } = useTranslation();

  let language =
    JSON.parse(localStorage.getItem("language"))?.lang === "en" ? 2 : 1;

  let lang = JSON.parse(localStorage.getItem("language"))?.lang;

  useEffect(() => {
    CourseList();
    let textOfPageArr = window.location.href.split("/");
    textOfPageArr.splice(textOfPageArr.length - 1, 1);
    setShareUrl(textOfPageArr.join("/"));
  }, []);

  const CourseList = async () => {
    try {
      let response = await api.paid_courses_list({
        language: language,
        filter: null,
      });
      if (response.data) {
        setCourseList(response.data.data);
        const uniqueLocations = [
          ...new Set(response.data.data.map((item) => item.location)),
        ];
        setFilterOptions(uniqueLocations);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedFilter) {
      const filteredList = courseList.filter(
        (item) => item.location === selectedFilter
      );
      setFilteredCourseList(filteredList);
    } else {
      setFilteredCourseList(courseList);
    }
  }, [selectedFilter, courseList]);

  return (
    <Fragment>
      <ShareHeader
        backTwice={location.state?.selectedCountry ? true : false}
        textToBeCopied={shareUrl}
        pageName={"Course"}
        path={"beasponser"}
      />
      <div className="course_new">
        <div className="child_main">
          <div></div>
          <div className="child_title">
            <label></label>
            <h1>{t("Available courses")}</h1>
          </div>
        </div>
      </div>
      <div className="filter">
        <div className="d-flex">
          <img src={filter} alt="filter" width="25px" />
          <select
            value={selectedFilter}
            onChange={(e) => setSelectedFilter(e.target.value)}
            className="child_filter"
            placeholder={
              lang === "en" ? "Filter by location" : "تصفية حسب الموقع"
            }
          >
            <option value="" defaultValue disabled>
              {lang === "en" ? "Filter by location" : "تصفية حسب الموقع"}
            </option>
            <option value="">All</option>
            {filterOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="main_carts">
        {filteredCourseList.map((item) => (
          <div className="child_carts" key={item.id}>
            <div className="image_main">
              <img src={item?.logo} alt="cartimage" className="cart_image" />
              <div className="amound">
                <p>
                  {item?.amount} <span>AED</span>
                </p>
              </div>
            </div>
            <div className="cart_body">
              <div className="icons_details">
                <div className="child_details">
                  <small>{item?.location}</small>
                  <img src={map_pin} alt="location" width="26px" />
                </div>
                <div className="child_details">
                  <small> {item?.time ?? " - - "} </small>
                  <img src={time} alt="filter" width="22px" />
                </div>
                <div className="child_details">
                  <small>{item?.date}</small>
                  <img src={calaneter} alt="calenter" width="18px" />
                </div>
              </div>
              <div className="title_descrie">
                <h3>{item?.title}</h3>
                <p>{item?.description}</p>
              </div>
              <div className="action_btn">
                {lang === "en" ? <p>Join Now</p> : <p>سجل الآن</p>}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default CourseNew;
